import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output, TemplateRef } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { IconComponent } from '@semmie/components/presentational/core/icon/icon.component';
import { LabelModule } from '@semmie/components/presentational/core/label/label.module';
import { SharedModule } from '@semmie/shared/shared.module';

import { TagComponent } from '@semmie/components/presentational/core/tag/tag.component';
import { HapticOnTapDirective } from '@semmie/directives/haptic-on-tap.directive';
import { Icon } from '@semmie/schemas';
import { StatusColor, StatusComponent } from '@semmie/components/presentational/core/status/status.component';
@Component({
  standalone: true,
  imports: [SharedModule, IonicModule, IconComponent, LabelModule, TagComponent, StatusComponent, HapticOnTapDirective],
  selector: 'semmie-selection-card',
  templateUrl: './selection-card.component.html',
  styleUrls: ['./selection-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectionCardComponent {
  @Input() icon: Icon;
  @HostBinding('attr.title') _title = '';
  @Input() title: string;
  @Input() titleSize: 'h3' | 'h4' = 'h3';
  @Input() description?: string;
  @Input() label?: string;
  @Input() status: string;
  @Input() selected = false;
  @Input() enabled = false;
  @Input() checkbox = false;
  @Input() checkboxTpl: TemplateRef<any>;
  @Input() clickable = true;

  @Output() onClick: EventEmitter<boolean> = new EventEmitter<boolean>();

  readonly Icon = Icon;
  readonly StatusColor = StatusColor;

  onTapped(): void {
    if (!this.clickable && this.enabled) return;
    this.onClick.emit();
  }
}

<div class="chart-container" tabindex="-1">
  <ng-container *ngIf="isLoaded$ | async; else loadingTpl">
    <highcharts-chart
      noVerticalScrollOnTouch
      disableSwipeOnTouch
      *ngIf="chartDataAvailable; else emptyTpl"
      [Highcharts]="Highcharts"
      [options]="chartOptionsLocal"
      (chartInstance)="chartCallback($event)"
      [(update)]="updateFlag"
    ></highcharts-chart>
  </ng-container>
</div>
<div *ngIf="chartDataAvailable && !hidePlotOptions" class="toggle-container relative">
  <ng-container *ngFor="let option of plotOptionKeys">
    <div
      *ngIf="plotOptions[option]?.available"
      class="toggle"
      [ngClass]="{ selected: option === this.selectedPlotOption }"
      (click)="onPlotOptionTypeChange(option)"
    >
      {{ plotOptions[option].label }}
    </div>
  </ng-container>

  <ng-container *ngIf="enablePlotPreferences">
    <div class="toggle" (click)="plotPreferences()">
      <semmie-icon [icon]="Icon.MORE"></semmie-icon>
    </div>
  </ng-container>
</div>

<ng-template #emptyTpl>
  <div class="flex-center absolute inset-0 flex-col">
    <semmie-empty-placeholder [text]="placeholder" [icon]="Icon.GRAPH"></semmie-empty-placeholder>
  </div>
</ng-template>

<ng-template #loadingTpl>
  <div class="flex-center absolute inset-0 flex-col">
    <div class="m-auto h-10 w-10">
      <semmie-loading-ripple position="inline"></semmie-loading-ripple>
    </div>
  </div>
</ng-template>

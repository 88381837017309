import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';

import * as Highcharts from 'highcharts';

import moment from 'moment';

import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from '@semmie/components/_abstract/base-component.component';
import { Icon } from '@semmie/schemas';
import { BehaviorSubject, of } from 'rxjs';
import { HapticFeedbackService } from '@semmie/services/haptic-feedback/haptic-feedback.service';
import { YAxisOptions } from 'highcharts';
import { ChartPlotPreference } from '@semmie/schemas/bi/chart';
import { ImpactStyle } from '@capacitor/haptics';
import { PlatformService } from '@semmie/services/platform/platform.service';
import { delay } from 'rxjs/operators';
import { Utils } from '@onyxx/utility/general';
import { PlotOptionType } from '@onyxx/model/account';

export interface PlotOption {
  label: string;
  available: boolean;
}

export type SelectionDetails = {
  profit: number;
  value: number;
  date: number;
  plotPreference?: ChartPlotPreference;
};

@Component({
  selector: 'semmie-advanced-chart-deprecated',
  templateUrl: './advanced-chart-deprecated.component.html',
  styleUrls: ['./advanced-chart-deprecated.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdvancedChartDeprecatedComponent extends BaseComponent implements OnInit, OnChanges {
  @Input()
  set backgroundColor(backgroundColor: string) {
    this.backgroundColor$.next(backgroundColor);
  }

  @Input()
  set type(type: string) {
    this.type$.next(type);
  }

  @Input() data: any;
  @Input() chartData: Array<any> = [];

  @Input({ required: false }) selectedPlotOption: PlotOptionType | undefined;
  @Input() hidePlotOptions = false;
  @Input() hideXAxisLabels = true;

  @Input() selectedPlotPreference = ChartPlotPreference.Index;
  @Input() enablePlotPreferences = true;
  @Input() placeholder = $localize`:@@advanced-chart.no-data:There is no data to display.`;

  @Output() onChartLoaded = new EventEmitter<boolean>();
  @Output() onChartTouch = new EventEmitter<PointerEvent>();
  @Output() onChartSelectionChange = new EventEmitter<SelectionDetails>();
  @Output() onChartPlotOptionChange = new EventEmitter<PlotOptionType>();
  @Output() plotPreferencesClicked: EventEmitter<any> = new EventEmitter<void>();

  readonly Highcharts: typeof Highcharts = Highcharts;
  readonly Icon = Icon;

  source: any;

  readonly plotOptions: { [key in PlotOptionType]: PlotOption } = {
    '1m': {
      label: '1m',
      available: true,
    },
    '3m': {
      label: '3m',
      available: true,
    },
    '6m': {
      label: '6m',
      available: true,
    },
    ytd: {
      label: 'ytd',
      available: true,
    },
    std: {
      label: 'std',
      available: true,
    },
  };

  readonly chartOptions: Highcharts.Options = {
    title: {
      text: '',
    },
    credits: {
      enabled: false,
    },
    chart: {
      height: '210',
      type: 'spline',
      backgroundColor: 'transparent',
      spacing: [20, 0, 0, 0],
      style: {
        fontFamily: 'VAG Rounded Next',
      },
    },
    xAxis: {
      title: {
        text: '',
      },
      crosshair: {
        color: 'var(--color-advanced-chart-crosshair)',
        dashStyle: 'Solid',
      },
      type: 'datetime',
      tickAmount: 0,
      tickColor: 'transparent',
      lineColor: 'transparent',
      visible: false,
      minPadding: 0,
      maxPadding: 0,
    },
    yAxis: {
      title: {
        text: '',
      },
      visible: true,
      minPadding: 0,
      maxPadding: 0,
      left: 0,
      opposite: false,
      gridLineDashStyle: 'Dot',
      gridLineColor: 'var(--color-advanced-chart-grid-line)',
      labels: {
        align: 'left',
        x: 20,
        y: -10,
        reserveSpace: false,
        style: {
          color: 'var(--color-advanced-chart-labels)',
          fontSize: '10px',
        },
        formatter: (function (chartComponent) {
          return function () {
            const label = this.axis.defaultLabelFormatter.call(this);
            if (chartComponent.selectedPlotPreference === ChartPlotPreference.Index) {
              return `${label}%`;
            }

            const value = Number(label);

            if (value >= 1000000) return `€ ${value / 1000000}M`;
            if (value >= 1000) return `€ ${value / 1000}K`;
            return `€ ${label.replace('k', 'K')}`;
          };
        })(this),
      },
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      useHTML: true,
      backgroundColor: 'transparent',
      hideDelay: 300,
      borderWidth: 0,
      shadow: false,
      padding: 0,
      headerFormat: '',
      pointFormat: '',
      formatter: (function (chartComponent) {
        return function () {
          const profit = parseFloat(this.point.options.custom?.profit);
          const value = this.point.options.custom?.value;
          const date = this.point.options.custom?.date;
          const plotPreference = this.point.options.custom?.plotPreference;

          chartComponent.onChartSelectionChange.emit({ profit, value, date, plotPreference });

          return '';
        };
      })(this),
    },
    plotOptions: {
      series: {
        turboThreshold: 0,
        marker: {
          enabled: false,
          lineWidth: 3,
          radius: 4,
          symbol: 'circle',
        },
        states: {
          hover: {
            lineWidth: 2,
          },
        },
        events: {
          mouseOver: (function (chartComponent) {
            return function ($event) {
              chartComponent.chartFocused = true;
              chartComponent.onChartTouch.emit($event);
            };
          })(this),
          mouseOut: (function (chartComponent) {
            return function ($event) {
              chartComponent.chartFocused = false;
              chartComponent.onChartTouch.emit($event);
            };
          })(this),
        },
      },
    },
    series: [
      {
        custom: [],
        type: 'spline',
        lineWidth: 2,
        color: 'var(--color-advanced-chart-series-line)',
        data: [],
        zones: [],
        pointStart: 0,
        pointIntervalUnit: 'day',
        zoneAxis: 'x',
        animation: {
          duration: 800,
          easing: 'easeOut',
        },
        marker: {
          enabled: false,
          states: {
            hover: {
              enabled: true,
            },
          },
        },
      },
    ],
  };

  /**
   * Local copy of the passed chartOptions.
   * This is necessary because HighCharts directly mutates any passed option.
   */
  chartOptionsLocal: Highcharts.Options;

  updateFlag = false;

  /**
   * Complete 'loading' after a predefined delay in order
   * to save some resources on the MainThread
   */
  isLoaded$ = of(true).pipe(delay(400));

  private chartFocused = false;

  private backgroundColor$ = new BehaviorSubject<string>('transparent');
  private type$ = new BehaviorSubject<string>('line');

  constructor(
    private cdr: ChangeDetectorRef,
    private platformService: PlatformService,
    private hapticFeedbackService: HapticFeedbackService,
    private translate: TranslateService,
  ) {
    super();
  }

  ngOnInit() {
    /**
     * Ugly workaround because Highcharts is literally modifying the passed data.
     */
    /* eslint-disable @typescript-eslint/no-non-null-assertion */
    this.chartOptionsLocal = JSON.parse(JSON.stringify(this.chartOptions));
    (this.chartOptionsLocal.yAxis as YAxisOptions).labels!.formatter = (this.chartOptions.yAxis as YAxisOptions).labels!.formatter;
    this.chartOptionsLocal.tooltip!.formatter = this.chartOptions.tooltip!.formatter;
    this.chartOptionsLocal.plotOptions!.series!.events = this.chartOptions.plotOptions!.series!.events;
    /* eslint-enable @typescript-eslint/no-non-null-assertion */

    if (!Array.isArray(this.chartOptionsLocal!.xAxis) && Utils.isNotNil(this.chartOptionsLocal?.xAxis)) {
      this.chartOptionsLocal.xAxis.visible = !this.hideXAxisLabels;
    }

    // set translations and date label formatting
    Highcharts.setOptions({
      lang: {
        months: moment.months(),
        shortMonths: moment.monthsShort(),
        weekdays: moment.weekdays(),
        shortWeekdays: moment.weekdaysShort(),
      },
      xAxis: {
        dateTimeLabelFormats: {
          day: '%e %b',
          week: '%e %b',
          month: '%b\u2019%y',
        },
      },
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { chartData, selectedPlotOption } = changes;

    // chart date changed
    if (chartData && !chartData.firstChange && !Utils.isEqual(chartData.previousValue, chartData.currentValue)) {
      this.plotChart();
      return;
    }

    // selectedPlotOption changed
    if (
      selectedPlotOption &&
      !selectedPlotOption.firstChange &&
      !Utils.isEqual(selectedPlotOption.previousValue, selectedPlotOption.currentValue)
    ) {
      this.plotChart();
    }
  }

  get focused(): boolean {
    return !!this.chartFocused;
  }

  get plotOptionKeys(): Array<PlotOptionType> {
    return Object.keys(this.plotOptions) as PlotOptionType[];
  }

  get chartDataAvailable() {
    return !!this.chartData?.length;
  }

  chartCallback(chartInput: Highcharts.Chart | null) {
    if (Utils.isNil(chartInput)) return;
    const chart = chartInput;

    const chartContainer = document.getElementsByClassName('highcharts-container')[0];

    function hideMarkers(e: Highcharts.ChartClickEventObject) {
      if (!this.chartDataAvailable) return;

      const event = chart.pointer.normalize(e);

      chart.series.forEach(function (s) {
        const point = s.searchPoint(event, true);

        if (point) {
          point.setState('');
        }
      });

      chart.pointer.reset(false, 0);
      chart.xAxis[0].hideCrosshair();

      chart.redraw();

      if (this.chartDataAvailable && this.focused) {
        this.hapticFeedbackService.interact(ImpactStyle.Heavy);
      }
    }

    function hapticTouchFeedback() {
      if (this.chartDataAvailable && this.focused) {
        this.hapticFeedbackService.interact(ImpactStyle.Heavy);
      }
    }

    function hapticScrubFeedback() {
      if (this.chartDataAvailable && this.focused) {
        this.hapticFeedbackService.interact(ImpactStyle.Light);
      }
    }

    if (this.platformService.isApp) {
      if (this.platformService.isRunningOn('ios')) {
        chartContainer.addEventListener('touchmove', hapticScrubFeedback.bind(this));
      }

      chartContainer.addEventListener('touchstart', hapticTouchFeedback.bind(this));
      chartContainer.addEventListener('touchend', hideMarkers.bind(this));
      chartContainer.addEventListener('touchcancel', hideMarkers.bind(this));
    } else {
      chartContainer.addEventListener('click', hideMarkers.bind(this));
    }

    this.updatePlotOptionsAvailability();
    this.plotChart();
  }

  onPlotOptionTypeChange(plot: PlotOptionType): void {
    if (!this.plotOptions[plot].available) return;

    this.hapticFeedbackService.interact(ImpactStyle.Light);
    this.onChartPlotOptionChange.emit(plot);
  }

  plotChart(): void {
    switch (this.selectedPlotOption) {
      case 'ytd':
        this.plotChartYtd();
        break;
      case '6m':
        this.plotChartByMonth(6);
        break;
      case '3m':
        this.plotChartByMonth(3);
        break;
      case '1m':
        this.plotChartByMonth(1);
        break;
      case 'std':
      default:
        this.plotChartStd();
        break;
    }

    this.updateFlag = true;
    this.cdr.detectChanges();
  }

  plotPreferences(): void {
    this.hapticFeedbackService.interact(ImpactStyle.Light);
    this.plotPreferencesClicked.emit();
  }

  private plotChartStd(): boolean {
    if (this.chartOptionsLocal.series?.[0].type === 'spline') {
      this.chartOptionsLocal.series[0].data = this.chartData.slice();
    }

    return this.chartData?.length > 1;
  }

  private plotChartYtd(): boolean {
    const today: moment.Moment = moment();
    const thisYear = moment(today).startOf('year');
    const filteredDates = this.chartData.slice().filter((data) => moment(data.x).isBetween(thisYear, today));

    if (this.chartOptionsLocal.series?.[0].type === 'spline') {
      this.chartOptionsLocal.series[0].data = filteredDates;
    }

    return filteredDates?.length > 1;
  }

  private plotChartByMonth(offset: number): boolean {
    const today: moment.Moment = moment();
    const monthOffset = moment(today).subtract(offset, 'month');
    const filteredDates = this.chartData.slice().filter((data) => moment(data.x).isBetween(moment(monthOffset), moment(today)));

    if (this.chartOptionsLocal.series?.[0].type === 'spline') {
      this.chartOptionsLocal.series[0].data = filteredDates;
    }

    return filteredDates?.length > 1;
  }

  private updatePlotOptionsAvailability(): void {
    this.plotOptions['std'].available = this.plotChartStd();
    this.plotOptions['ytd'].available = this.plotChartYtd();
    this.plotOptions['6m'].available = this.plotChartByMonth(6);
    this.plotOptions['3m'].available = this.plotChartByMonth(3);
    this.plotOptions['1m'].available = this.plotChartByMonth(1);
  }
}

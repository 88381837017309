import { Injectable, inject } from '@angular/core';
import { APPLICATION_ENVIRONMENT } from '@onyxx/model/application-environment';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { Goal } from '@onyxx/model/goal';
import { HTTP_IMPLEMENTATION } from '@onyxx/model/http';
import { map } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class GoalProvider {
  private readonly http = inject(HTTP_IMPLEMENTATION);
  private readonly apiUrl = inject(APPLICATION_ENVIRONMENT).apiUrl;

  /**
   * Calculate if a goal is valid, if not give suggestions how we can achieve a valid goal
   * Returns a goal with the corresponding graph data or suggestions
   * @returns Goal
   */
  calculate(params: string) {
    return this.http.get<{ goal: Goal }>(`${this.apiUrl}/goal/calculate?${params}`).pipe(map((response) => response.goal));
  }

  /* DEVNOTE: Assess if this should be part of this provider or have it's own calculations provider */
  annualSpace(params: string) {
    return this.http.get(`${this.apiUrl}/calculations/annual_margin?${params}`);
  }

  get(accountId: string) {
    return this.http.get<{ goal: Goal }>(`${this.apiUrl}/accounts/${accountId}/goal`).pipe(map((response) => response.goal));
  }

  update(accountId: string, goal: Partial<Goal>) {
    return this.http.patch<{ goal: Goal }>(`${this.apiUrl}/accounts/${accountId}/goal`, { goal }).pipe(map((response) => response.goal));
  }
}

import { TransactionKind } from '@semmie/schemas/bi/transaction/transaction-kind';
import { iTransaction, iTransactionIndicator } from '@semmie/schemas/bi/transaction/transaction';
import { BaseModel } from '@semmie/models';
import { Icon } from '@semmie/schemas';
import { Formatter } from '@onyxx/utility/formatter';

export class Transaction extends BaseModel<iTransaction> {
  id: string; // Added for future use, not yet available in the API
  transaction_at: string;
  kind: TransactionKind;
  from_title: string;
  from_iban: string;
  description: string;
  invoice_amount: number;
  title: string;
  price: number;
  quantity: number;

  constructor(transaction: iTransaction) {
    super(transaction);

    if (transaction?.invoice_amount) {
      this.invoice_amount = Number(transaction.invoice_amount);
    }
    if (transaction?.price) {
      this.price = Number(transaction.price);
    }
    if (transaction?.quantity) {
      this.quantity = Number(transaction.quantity);
    }
  }

  get formattedIban() {
    return Formatter.formatIban(this.from_iban);
  }

  get indicator(): iTransactionIndicator {
    return {
      type:
        [TransactionKind.Deposit, TransactionKind.Dividend].includes(this.kind) ||
        (this.kind === TransactionKind.Stock && this.invoice_amount < 0)
          ? 'down'
          : 'up',
      icon:
        [TransactionKind.Deposit, TransactionKind.Dividend].includes(this.kind) ||
        (this.kind === TransactionKind.Stock && this.invoice_amount < 0)
          ? Icon.ARROWDOWN
          : Icon.ARROWUP,
    };
  }
}

<div
  class="selection-card"
  [class.selection-card--enabled]="enabled"
  [class.selection-card--selected]="enabled && selected"
  [class.selection-card--clickable]="clickable && enabled"
  (click)="onTapped()"
  hapticOnTap
>
  <div *ngIf="label" class="selection-card__label-holder">
    <semmie-tag [tagTheme]="selected ? 'primary' : 'variantE'" [text]="label"></semmie-tag>
  </div>
  <div class="selection-card__container">
    <div class="selection-card__content">
      <div *ngIf="icon" class="selection-card__icon">
        <semmie-icon [icon]="icon"></semmie-icon>
      </div>
      <div *ngIf="title" class="selection-card__title">
        <semmie-label
          type="heading"
          [size]="titleSize"
          [weight]="titleSize === 'h4' ? 'regular' : 'medium'"
          [text]="title | translate"
        ></semmie-label>
        <semmie-status *ngIf="status" [statusColor]="StatusColor.PRIMARY" [label]="status" />
      </div>
      <div *ngIf="description" class="selection-card__description">
        <semmie-label type="text" size="base_3" [text]="description | translate"></semmie-label>
      </div>
    </div>
    <div *ngIf="checkbox" class="selection-card__checkbox">
      <ng-container *ngIf="checkboxTpl" [ngTemplateOutlet]="checkboxTpl"></ng-container>
    </div>
    <span *ngIf="!enabled && !icon" class="selection-card__lock">
      <semmie-icon [icon]="Icon.LOCKED" size="xl"></semmie-icon>
    </span>
  </div>
</div>

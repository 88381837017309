import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

import { ValidatorMessage } from '@semmie/models/validators/validator-message';
import { RegExpPatterns } from '@semmie/shared/regexp';

export function password(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control?.value;
    if (!value) return null;

    const hasMinimumChars = RegExpPatterns.HAS_EIGHT_CHARACTERS.test(value);
    const hasUppercaseLetter = RegExpPatterns.HAS_UPPERCASE_LETTER.test(value);
    const hasLowercasedLetter = RegExpPatterns.HAS_LOWERCASE_LETTER.test(value);
    const hasDigit = RegExpPatterns.HAS_DIGIT.test(value);

    const validPassword = hasMinimumChars && hasUppercaseLetter && hasLowercasedLetter && hasDigit;

    if (!validPassword) {
      return new ValidatorMessage({
        code: 14,
        message: 'validation.password.invalid',
      });
    }

    return null;
  };
}

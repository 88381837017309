import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

import { Utils } from '@semmie/shared/utils';
import { ValidatorMessage } from '@semmie/models/validators/validator-message';

export function required(customValidation?: string, property?: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = property ? control?.value[property] : control?.value;

    if (Utils.isNullOrUndefined(value) || value === '' || (Array.isArray(value) && value.length === 0)) {
      return new ValidatorMessage({
        code: 1,
        message: customValidation ? customValidation : 'validation.required',
      });
    }

    return null;
  };
}

<ng-container
  *ngIf="{
    uploadComponent: uploadComponent$ | async,
  } as ctx"
>
  @if (ctx.uploadComponent) {
    @switch (ctx.uploadComponent.layout) {
      @case (UploadLayout.Image) {
        <div class="image-upload">
          <div (click)="showUploadModal()" class="image">
            @if (ctx.uploadComponent.imgSrc) {
              <div class="image-container">
                <semmie-image [src]="ctx.uploadComponent.imgSrc" width="175" height="175" objectFit="objectCover"></semmie-image>
              </div>
            }
            <div class="image-container">
              <div class="image-container-wrapper">
                <semmie-icon [icon]="Icon.PHOTO"></semmie-icon>
              </div>
            </div>
          </div>

          <div class="checkbox" [class.checked]="ctx.uploadComponent.fileSelected">
            @if (ctx.uploadComponent.fileSelected) {
              <semmie-icon size="xxs" [icon]="Icon.CHECKMARK"></semmie-icon>
            }
          </div>
        </div>
      }
      @case (UploadLayout.Button) {
        <semmie-label
          class="link block"
          [text]="buttonLayoutLabel$$()"
          size="base_3"
          [align]="'center'"
          (onClick)="showUploadModal()"
        ></semmie-label>
      }
      @case (UploadLayout.Document) {
        @if (useCardLayout) {
          <semmie-card
            [cardStyle]="cardStyle"
            padding="false"
            [disabled]="ctx.uploadComponent.state !== UploadState.empty"
            (onClick)="showUploadModal()"
          >
            <ng-container *ngTemplateOutlet="documentTpl"></ng-container>
          </semmie-card>
        } @else {
          <div (click)="ctx.uploadComponent.state !== UploadState.empty ? '' : showUploadModal()">
            <ng-container *ngTemplateOutlet="documentTpl; context: { listLayout: true }"></ng-container>
          </div>
        }

        <ng-template #documentTpl let-listLayout="listLayout">
          <div class="document-upload" [class.list-layout]="listLayout" [class.clickable]="ctx.uploadComponent.state === UploadState.empty">
            <div class="document-upload-left">
              <semmie-label
                class="title"
                type="heading"
                size="h3"
                weight="regular"
                [text]="invertTitles ? ctx.uploadComponent.subtitle : ctx.uploadComponent.title"
              ></semmie-label>
              @if (ctx.uploadComponent.error) {
                <semmie-label class="error" size="base_3" [text]="ctx.uploadComponent.error"></semmie-label>
              } @else {
                <semmie-label
                  class="subtitle truncate"
                  size="base_3"
                  [text]="invertTitles ? ctx.uploadComponent.title : ctx.uploadComponent.subtitle"
                ></semmie-label>
              }
            </div>
            <div class="document-upload-right">
              <semmie-fab-button
                [@deleteButton]="
                  [UploadState.uploaded, UploadState.selected, UploadState.deleting, UploadState.error].includes(ctx.uploadComponent.state)
                    ? 'active'
                    : 'inactive'
                "
                class="deleteButton"
                (onClick)="doDelete(value)"
                size="xs"
                theme="contrast"
                [clickable]="[UploadState.uploaded, UploadState.selected, UploadState.error].includes(ctx.uploadComponent.state)"
                [icon]="Icon.DELETE"
                iconSize="xxs"
              ></semmie-fab-button>
              <semmie-fab-button
                [@buttonState]="
                  [UploadState.empty, UploadState.selected, UploadState.error].includes(ctx.uploadComponent.state) ? 'active' : 'inactive'
                "
                size="xs"
                [clickable]="false"
                theme="default"
                [icon]="Icon.UPLOAD"
                iconSize="xxs"
              ></semmie-fab-button>
              <semmie-fab-button
                [@buttonState]="ctx.uploadComponent.state === UploadState.uploading ? 'active' : 'inactive'"
                (onClick)="clear()"
                size="xs"
                theme="contrast"
                [clickable]="ctx.uploadComponent.state === UploadState.uploading"
                [icon]="Icon.CLOSE"
                iconSize="xxs"
              ></semmie-fab-button>
              <semmie-fab-button
                [@buttonState]="[UploadState.uploaded, UploadState.deleting].includes(ctx.uploadComponent.state) ? 'active' : 'inactive'"
                size="xs"
                theme="primary"
                [clickable]="false"
                [icon]="Icon.CHECKMARK"
                iconSize="xxs"
              ></semmie-fab-button>

              <div
                [@loaderState]="[UploadState.uploading, UploadState.processing].includes(ctx.uploadComponent.state) ? 'active' : 'inactive'"
                class="loader-holder"
              >
                <semmie-image width="42" height="42" src="img/common/loader-upload.svg"></semmie-image>
              </div>
            </div>
          </div>
        </ng-template>
      }
    }
  }
</ng-container>

import { Directive, ElementRef, HostBinding, HostListener } from '@angular/core';

const enum ScrollBehavior {
  None,
  Scrolling,
  Closing,
}

@Directive({
  selector: '[semmieModalStopScrollGestureOverlapDirective]',
  standalone: true,
})
export class ModalStopScrollGestureOverlapDirective {
  private scrollBehavior = ScrollBehavior.None;
  private lastTouch: TouchEvent | null = null;

  constructor(private el: ElementRef) {}

  @HostBinding('class')
  get elementClass(): string {
    return this.scrollBehavior === ScrollBehavior.Closing ? 'overflow-y-hidden overscroll-none' : 'overflow-y-auto';
  }

  @HostListener('touchstart', ['$event']) onTouchStart(e: TouchEvent) {
    this.lastTouch = e;
    this.scrollBehavior = ScrollBehavior.None;
  }

  @HostListener('touchmove', ['$event']) onTouchMove(e: TouchEvent) {
    const deltaY = (e.targetTouches[0]?.screenY ?? 0) - (this.lastTouch?.targetTouches[0]?.screenY ?? 0);
    this.scrollBehavior = this.el.nativeElement.scrollTop <= 0 && deltaY > 0 ? ScrollBehavior.Closing : ScrollBehavior.Scrolling;

    if (this.scrollBehavior === ScrollBehavior.Closing) {
      // prevent small visual glitches where the content is scrolled a bit too far.
      this.el.nativeElement.scrollTop = 0;
    }

    if (this.scrollBehavior === ScrollBehavior.Scrolling) {
      // prevent ionic from closing modal
      e.stopPropagation();
    }
  }

  @HostListener('touchend') onTouchEnd() {
    this.scrollBehavior = ScrollBehavior.None;
    this.lastTouch = null;
  }
}

import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

import { ValidatorMessage } from '@semmie/models/validators/validator-message';
import { SemmieCurrencyPipe } from '@semmie/pipes/currency/currency.pipe';
import { Utils } from '@onyxx/utility/general';
import * as denotations from '@semmie/shared/validation/denotations.json';

import { Formatter } from '../validator-formatter';

@Injectable({ providedIn: 'root' })
export class MinValidator {
  constructor(private semmieCurrencyPipe: SemmieCurrencyPipe) {}

  /**
   * Check if the value is a number and is greater than or equal to the given minimum.
   * @param min The minimum value.
   * @param property The property of the value. This is used when the value is an object.
   * @param denotation The denotation of the value. This is used to translate the error message.
   */
  min(min: number | string, property?: string, denotation?: string, translationKey?: string, formatter?: Formatter): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = property ? control?.value?.[property] : control?.value;

      const numericValue = parseFloat(value);

      if (numericValue < Number(min)) {
        denotation = denotation || denotations['default'][7]; // number
        const knownDenotation = Object.values(denotations).find((value) => (Utils.isEqual(value, denotation) ? value : undefined));
        const _denotation = Utils.isNotNil(knownDenotation) ? `validation.denotation.${knownDenotation}` : denotation;

        switch (formatter) {
          case Formatter.CURRENCY:
            min = this.semmieCurrencyPipe.transform(min);
            break;
        }

        return new ValidatorMessage({
          code: 7,
          message: translationKey ?? 'validation.numbers.min',
          message_data: {
            min: min,
            denotation: _denotation,
          },
        });
      }

      return null;
    };
  }
}

import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

import { parsePhoneNumber, validatePhoneNumberLength } from 'libphonenumber-js';
import getCountryISO2 from 'country-iso-3-to-2';

import { ValidatorMessage } from '@semmie/models/validators/validator-message';
import { Utils } from '@semmie/shared/utils';

/**
 * !todo: should be able to dynamically parse a number depending on the params
 */
export function phone(country: string): ValidatorFn {
  // console.log(country);
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control?.value;
    const iso2Country = getCountryISO2(country);
    const isNLD = country === 'NLD';

    if (Utils.isNullOrUndefined(value) || Utils.isNullOrUndefined(iso2Country)) return null;

    const tooShort = value.length < (isNLD ? 10 : 6);
    const tooLong = value.length > (isNLD ? 10 : 20);

    let validateMsg;
    if (tooShort) {
      validateMsg = 'validation.phone.too_short';
    } else if (tooLong) {
      validateMsg = 'validation.phone.too_long';
    } else if (!validatePhoneNumberLength(value, iso2Country) && !parsePhoneNumber(value, iso2Country).isValid()) {
      validateMsg = 'validation.phone.invalid';
    }

    if (Utils.isNonNullOrUndefined(validateMsg)) {
      return new ValidatorMessage({
        code: 6,
        message: validateMsg,
      });
    }

    return null;
  };
}

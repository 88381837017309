import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { createFeature, createReducer, createSelector, on } from '@ngrx/store';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { Account } from '@onyxx/model/account';
import { visibleAccountsListApiActions } from './visible-accounts-list-api.actions';
import { visibleAccountsListCommonActions } from './visible-accounts-list-common.actions';
import { PaginationMeta } from '@onyxx/model/pagination';
import { Utils } from '@onyxx/utility/general';

export interface State extends EntityState<Account> {
  paginationMeta: PaginationMeta | null;
  initialized: boolean;
}

const adapter = createEntityAdapter<Account>();

const initialState: State = adapter.getInitialState({
  paginationMeta: null,
  selectedAccount: null,
  initialized: false,
});

const updateMetaData = (paginationMeta: PaginationMeta | null, action: 'increment' | 'decrement'): PaginationMeta | null => {
  if (Utils.isNil(paginationMeta)) {
    return paginationMeta;
  }

  const delta = action === 'increment' ? 1 : -1;
  const newTotalCount = paginationMeta.total_count + delta;

  let newTotalPages = paginationMeta.total_pages;
  if (newTotalCount === 0) {
    newTotalPages = 0;
  } else if (newTotalCount > 0 && newTotalPages === 0) {
    newTotalPages = 1;
  }

  const newPaginationMeta: PaginationMeta = {
    ...paginationMeta,
    total_count: newTotalCount,
    total_pages: newTotalPages,
  };

  return newPaginationMeta;
};

const reducer = createReducer(
  initialState,

  on(
    visibleAccountsListApiActions.reloadAccountsSuccess,
    (state, { accounts, paginationMeta }): State => ({
      ...adapter.setAll(accounts, state),
      paginationMeta,
      initialized: true,
    }),
  ),

  on(
    visibleAccountsListApiActions.loadPageSuccess,
    (state, { accounts, paginationMeta }): State => ({
      ...adapter.addMany(accounts, state),
      paginationMeta,
      initialized: true,
    }),
  ),

  on(
    visibleAccountsListCommonActions.addToAccountCache,
    (state, { account }): State => ({
      ...adapter.addOne(account, state),
      paginationMeta: updateMetaData(state.paginationMeta, 'increment'),
    }),
  ),

  on(
    visibleAccountsListCommonActions.updateAccountCache,
    (state, { account }): State =>
      adapter.updateOne(
        {
          id: account.id,
          changes: account,
        },
        state,
      ),
  ),

  on(
    visibleAccountsListCommonActions.removeFromAccountCache,
    (state, { id }): State => ({
      ...adapter.removeOne(id, state),
      paginationMeta: updateMetaData(state.paginationMeta, 'decrement'),
    }),
  ),

  on(visibleAccountsListCommonActions.clear, (): State => initialState),
);

export const visibleAccountsListFeature = createFeature({
  name: 'visibleAccountsList',
  reducer,
  extraSelectors: ({ selectVisibleAccountsListState, selectEntities, selectPaginationMeta }) => ({
    ...adapter.getSelectors(selectVisibleAccountsListState),
    selectAccountsWithMeta: createSelector(selectEntities, selectPaginationMeta, (accountsMap, paginationMeta) => {
      if (Utils.isNil(paginationMeta)) {
        return null;
      }
      return {
        data: Object.values(accountsMap).filter(Utils.isNotNil),
        meta: paginationMeta,
      };
    }),
  }),
});

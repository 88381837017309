import { inject } from '@angular/core';
import { Actions, OnInitEffects, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { PushNotificationsProvider } from '@onyxx/provider/notifications';
import { EMPTY, catchError, combineLatest, defer, filter, map, of, startWith, switchMap, tap } from 'rxjs';
import { PushNotificationCommonActions } from './push-notifications-common.actions';
import { PushNotificationPlatform } from '@onyxx/model/notifications';
import { Platform } from '@ionic/angular';
import { Utils } from '@onyxx/utility/general';
import { Store } from '@ngrx/store';
import { pushNotificationsFeature } from './push-notifications.reducer';
import { AppStorageService, ToastService, PlatformService } from '@semmie/services';
import { UserStoreFacade } from '@semmie/store/user';
import { ToasterStyle } from '@semmie/schemas/components/toast';
import { PushNotificationsService } from './capacitor-helpers/push-notifications.service';
import { PUSH_NOTIFICATION_CONFIG } from './config/push-notifications-config.token';
import { filterNil } from '@onyxx/utility/observables';

export const enum NotificationsStorageKey {
  NotificationsEnrolled = 'notifications-enrolled',
}

export class PushNotificationsEffects implements OnInitEffects {
  private readonly actions$ = inject(Actions);
  private readonly store = inject(Store);
  private readonly pushNotificationProvider = inject(PushNotificationsProvider);
  private readonly userStoreFacade = inject(UserStoreFacade);
  private readonly platformService = inject(PlatformService);
  private readonly appStorageService = inject(AppStorageService);
  private readonly toastService = inject(ToastService);
  private readonly pushNotificationsService = inject(PushNotificationsService);
  private readonly pushNotificationsConfigToken = inject(PUSH_NOTIFICATION_CONFIG);
  private readonly pushNotificationsConfig = inject(this.pushNotificationsConfigToken);
  protected platform = inject(Platform);

  private readonly pushNotificationEnrolledStorage = this.appStorageService.createStorageReader<boolean>(
    NotificationsStorageKey.NotificationsEnrolled,
  );

  private readonly enrolledLocalStorage$ = defer(() => this.pushNotificationEnrolledStorage.get()).pipe(
    map((storedValue) => storedValue ?? false),
  );

  readonly initializeListeners$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PushNotificationCommonActions.initialize),
      filter(() => this.platformService.isApp),
      switchMap(() => this.pushNotificationsService.nativeEvents$),
      map((event) => {
        switch (event.type) {
          case 'registration':
            return PushNotificationCommonActions.registrationSuccess({ token: event.data.value });
          case 'registrationError':
            return PushNotificationCommonActions.registrationError({ error: event.data.error });
          case 'pushNotificationReceived':
            return PushNotificationCommonActions.pushNotificationReceived({ notification: event.data });
          case 'pushNotificationActionPerformed':
            return PushNotificationCommonActions.pushNotificationAction({
              notification: event.data.notification,
            });
        }
      }),
    );
  });

  readonly checkPermission$ = createEffect(() => {
    return combineLatest([
      this.actions$.pipe(ofType(PushNotificationCommonActions.initialize)),
      this.platform.resume.pipe(startWith(null)),
    ]).pipe(
      filter(() => this.platformService.isApp),
      switchMap(() => defer(() => this.pushNotificationsService.checkPermissions())),
      map((permission) => PushNotificationCommonActions.permissionUpdated({ permission })),
    );
  });

  readonly register$ = createEffect(
    () => {
      return combineLatest([
        this.actions$.pipe(ofType(PushNotificationCommonActions.initialize)),
        this.platform.resume.pipe(startWith(null)),
      ]).pipe(
        filter(() => this.platformService.isApp),
        switchMap(() => {
          return defer(() => this.pushNotificationsService.register());
        }),
      );
    },
    { dispatch: false },
  );

  readonly initializeEnrolled = createEffect(() => {
    return this.actions$.pipe(
      ofType(PushNotificationCommonActions.initialize),
      switchMap(() => this.userStoreFacade.readyNotification$),
      switchMap(() => this.enrolledLocalStorage$),
      map((enrolled) => PushNotificationCommonActions.enrolledUpdated({ enrolled })),
    );
  });

  readonly updateEnrolled$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PushNotificationCommonActions.enroll),

      switchMap(async () => {
        // request permission when enabling. If already granted or revoked, the call will
        // return the current status
        return await this.pushNotificationsService.requestPermissions();
      }),
      switchMap((permissionStatus) => {
        if (permissionStatus.receive !== 'granted') {
          return of([permissionStatus, null] as const);
        }
        // wait for the device token to be available
        return this.store.select(pushNotificationsFeature.selectDeviceToken).pipe(
          filterNil(),
          map((token) => [permissionStatus, token] as const),
        );
      }),
      switchMap(([permissionStatus, token]) => {
        const platform = this.pushNotificationPlatform();
        if (permissionStatus.receive !== 'granted') {
          return of(PushNotificationCommonActions.permissionUpdated({ permission: permissionStatus }));
        }
        if (Utils.isNil(platform) || Utils.isNil(token)) {
          this.toastService.show({
            header: $localize`:@@error.generic.title:An error has occurred`,
            message: $localize`:@@push-notifications.enable-fail-toast.message:Could not enable push notifications. Please try again later.`,
            style: ToasterStyle.DANGER,
          });
          return of(PushNotificationCommonActions.permissionUpdated({ permission: permissionStatus }));
        }

        return this.pushNotificationProvider
          .addDeviceToken({
            token,
            platform,
          })
          .pipe(
            switchMap(() => defer(() => this.pushNotificationEnrolledStorage.set(true))),
            // read from local storage after set to ensure that there is only one source of truth
            switchMap(() => this.enrolledLocalStorage$),
            map((enrolled) => PushNotificationCommonActions.enrolledAndPermissionUpdated({ enrolled, permission: permissionStatus })),
            catchError(() => {
              this.toastService.show({
                header: $localize`:@@error.generic.title:An error has occurred`,
                message: $localize`:@@push-notifications.enable-fail-toast.message:Could not enable push notifications. Please try again later.`,
                style: ToasterStyle.DANGER,
              });

              return of(PushNotificationCommonActions.permissionUpdated({ permission: permissionStatus }));
            }),
          );
      }),
    );
  });

  readonly unEnroll$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PushNotificationCommonActions.unEnroll),
      concatLatestFrom(() => this.store.select(pushNotificationsFeature.selectDeviceToken)),
      switchMap(([, token]) => {
        const platform = this.pushNotificationPlatform();
        if (Utils.isNil(platform) || Utils.isNil(token)) {
          return EMPTY;
        }

        return this.pushNotificationProvider.removeDeviceToken(token).pipe(
          catchError(() => {
            this.toastService.show({
              header: $localize`:@@error.generic.title:An error has occurred`,
              message: $localize`:@@push-notifications.disable-fail-toast.message:Could not disable push notifications. Please try again later.`,
              style: ToasterStyle.DANGER,
            });

            return EMPTY;
          }),
        );
      }),
      switchMap(() => defer(() => this.pushNotificationEnrolledStorage.set(false))),
      // read from local storage after set to ensure that there is only one source of truth
      switchMap(() => this.enrolledLocalStorage$),
      map((enrolled) => PushNotificationCommonActions.enrolledUpdated({ enrolled })),
    );
  });

  readonly notificationPerformed = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(PushNotificationCommonActions.pushNotificationAction),
        tap(({ notification }) => this.pushNotificationsConfig.handlePushNotificationAction(notification)),
      );
    },
    { dispatch: false },
  );

  readonly clear$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PushNotificationCommonActions.clear),
      concatLatestFrom(() => this.store.select(pushNotificationsFeature.selectDeviceToken)),
      switchMap(([, token]) => {
        if (Utils.isNotNil(token)) {
          return this.pushNotificationProvider.removeDeviceToken(token).pipe(
            // swallow API errors
            catchError(() => of(void 0)),
          );
        }

        return of(void 0);
      }),
      switchMap(() => defer(() => this.pushNotificationEnrolledStorage.set(false))),
      map(() => PushNotificationCommonActions.clearDone()),
    );
  });

  ngrxOnInitEffects() {
    return PushNotificationCommonActions.initialize();
  }

  private readonly pushNotificationPlatform = () => {
    const platform = this.platformService.getPlatform();
    switch (platform) {
      case 'ios':
        return PushNotificationPlatform.IOS;
      case 'android':
        return PushNotificationPlatform.ANDROID;
      case 'web':
        return null;
    }
  };
}

import { NgModule } from '@angular/core';

import { HighchartsChartModule } from 'highcharts-angular';

import { AdvancedChartComponent } from '@semmie/components/containers/advanced-chart/advanced-chart.component';
import { AdvancedChartDeprecatedComponent } from '@semmie/components/containers/advanced-chart/deprecated/advanced-chart-deprecated.component';
import { IconComponent } from '@semmie/components/presentational/core/icon/icon.component';
import { ImageModule, LabelModule, LoadingRippleModule } from '@semmie/components';
import { AssetPathPipeModule } from '@semmie/pipes/asset-path';
import { SharedModule } from '@semmie/shared/shared.module';
import { EmptyPlaceholderComponent } from '@semmie/components/presentational/core/empty-placeholder/empty-placeholder.component';

@NgModule({
  declarations: [AdvancedChartComponent, AdvancedChartDeprecatedComponent],
  imports: [
    SharedModule,
    HighchartsChartModule,
    ImageModule,
    LabelModule,
    IconComponent,
    AssetPathPipeModule,
    LoadingRippleModule,
    EmptyPlaceholderComponent,
  ],
  exports: [AdvancedChartComponent, AdvancedChartDeprecatedComponent],
})
export class AdvancedChartModule {}

import { Injectable } from '@angular/core';

import { ConfigStore } from '@semmie/store';
import { ModalService } from '@semmie/services/modal/modal.service';
import { PlatformService } from '@semmie/services/platform/platform.service';
import { Illustration } from '@semmie/shared/globals';

@Injectable({
  providedIn: 'root',
})
export class MaintenanceService {
  private modal: Promise<HTMLIonModalElement> | null = null;

  constructor(
    private configStore: ConfigStore,
    private modalService: ModalService,
    private platformService: PlatformService,
  ) {}

  modalIsOpen() {
    return !!this.modal;
  }

  /**
   * Check whether we have enabled a maintenance in the config
   */
  hasActiveMaintenance() {
    if (!this.modalIsOpen() && this.configStore.maintenance?.enabled) {
      this.modal = this.modalService.openMaintenanceModal({
        subtitle: this.configStore.maintenance?.subtitle ?? 'maintenance.subtitle',
        title: this.configStore.maintenance?.title ?? 'maintenance.title',
        description: this.configStore.maintenance?.description ?? 'maintenance.description',
        image: this.configStore.maintenance?.image ?? this.platformService.resolveAssetPath(Illustration.CONE),
        pollingDisabled: true,
      });

      this.modal.then(() => {
        this.modal = null;
      });

      return true;
    }

    return false;
  }

  openMaintenanceModal() {
    if (this.modalIsOpen()) return;

    this.modal = this.modalService.openMaintenanceModal({
      subtitle: 'maintenance.subtitle',
      title: 'maintenance.title',
      description: 'maintenance.description',
      image: this.platformService.resolveAssetPath(Illustration.CONE),
    });

    this.modal.then(() => {
      this.modal = null;
    });
  }
}

import { NgModule } from '@angular/core';
import { HttpBackend, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';

import { IonicModule } from '@ionic/angular';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { FullRouterStateSerializer, NavigationActionTiming, StoreRouterConnectingModule } from '@ngrx/router-store';

import { DynamicDialogModule } from 'primeng/dynamicdialog';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { SurpressDoubleTapDirective } from '@semmie/directives/surpress-double-tap.directive';
import { ScrollableModalModule } from '@semmie/components/containers/modals/root-modal/scrollable-modal.module';
import { SemmieComponents } from '@semmie/components/presentational/core/all';

import { getStoreConfig, getStoreDevtoolsOptions, routes, providers } from './app.common';
import { AppComponent } from './app.component';
import { UserStateModule } from '@semmie/store/user';
import { BoostersSoreModule } from '@semmie/store/boosters';
import { PaymentStoreModule } from '@semmie/store/payment';
import { environment } from 'environments/environment';
import { AdvisorAccountStoreModule } from '@semmie/store/advisor-account';

import { UserActivityStoreModule } from '@onyxx/store/user-activity';
import { RxIf } from '@rx-angular/template/if';
import { NotificationsStateModule } from '@onyxx/store/notifications';
import { BiometricsStateModule } from '@onyxx/store/biometrics';
import { HighlightsStateModule } from '@onyxx/store/highlights';
import { AccountStateModule } from '@onyxx/store/account';
import { AccountAdditionalDataStateModule } from '@onyxx/store/account-additional-data';
import { VisibleAccountsListStateModule } from '@onyxx/store/visible-accounts-list';
import { GoalStateModule } from '@onyxx/store/goal';
import { AppLoadingStatusStateModule } from '@onyxx/store/app-loading-status';
import { DirectDebitsStateModule } from '@onyxx/store/direct-debits';

export function createTranslateLoader(http: HttpBackend) {
  const client = new HttpClient(http);
  return new TranslateHttpLoader(client, './assets/i18n/', '.json');
}

@NgModule({
  bootstrap: [AppComponent],
  declarations: [AppComponent, SurpressDoubleTapDirective],
  imports: [
    RxIf,
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(routes, { paramsInheritanceStrategy: 'always', enableTracing: false }),
    ServiceWorkerModule.register(`ngsw-worker.js?v=${import.meta.env.NG_APP_BUILD_NUMBER}`, {
      enabled: environment.production,
    }),

    // Store dependencies
    StoreModule.forRoot(...getStoreConfig()),
    EffectsModule.forRoot(),
    StoreRouterConnectingModule.forRoot({
      navigationActionTiming: NavigationActionTiming.PostActivation,
      serializer: FullRouterStateSerializer,
    }),
    StoreDevtoolsModule.instrument(getStoreDevtoolsOptions()),

    IonicModule.forRoot({
      backButtonText: '',
      scrollAssist: false,
      scrollPadding: false,
      rippleEffect: false,
    }),
    TranslateModule.forRoot({
      defaultLanguage: 'nl',
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpBackend],
      },
    }),
    FormsModule,
    ReactiveFormsModule,

    SemmieComponents,
    ScrollableModalModule,
    DynamicDialogModule,
    UserActivityStoreModule,
    NotificationsStateModule,
    BiometricsStateModule,
    HighlightsStateModule,
    AppLoadingStatusStateModule,

    // TODO: These store modules to where they are being used (once the services that require them have been refactored)
    UserStateModule,
    BoostersSoreModule,
    PaymentStoreModule,
    AdvisorAccountStoreModule,
    AccountStateModule,
    AccountAdditionalDataStateModule,
    VisibleAccountsListStateModule,
    GoalStateModule,
    DirectDebitsStateModule,
  ],
  providers: [provideHttpClient(withInterceptorsFromDi()), ...providers],
})
export class AppModule {}

export type OnyxxImage = typeof EMPTY_IMAGE | ActionImage | Illustration | IllustrationSmall | IllustrationBooster;

export const EMPTY_IMAGE = 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==';

export enum ActionImage {
  FAILED = 'img/actions/failed.svg',
  SUCCESS = 'img/actions/success.svg',
  PULSE = 'img/actions/pulse.svg',
  LOADING = 'img/actions/loading.svg',
}

export enum Illustration {
  ACTIVATE = 'illustrations/activate.png',
  BADGE = 'illustrations/badge.png',
  BEAR = 'illustrations/bear.png',
  BELL = 'illustrations/bell.png',
  BOAT = 'illustrations/boat.png',
  BOX = 'illustrations/box.png',
  BUILDING_ECO = 'illustrations/building-eco.png',
  CALCULATOR = 'illustrations/calculator.png',
  CALENDAR = 'illustrations/calendar.png',
  CLIPBOARD = 'illustrations/clipboard.png',
  COINS = 'illustrations/coins.png',
  CONE = 'illustrations/cone.png',
  HOURGLASS = 'illustrations/hourglass.png',
  IDENTIFICATION = 'illustrations/identification.png',
  MAILBOX = 'illustrations/mailbox.png',
  MOUNTAIN = 'illustrations/mountain.png',
  NOTEPAD = 'illustrations/notepad.png',
  ORGANISATION_BUILDING = 'illustrations/organisation-building.png',
  PAPER_PLANE = 'illustrations/paper-plane.png',
  PHONE_MAIL_TEAR = 'illustrations/phone-mail-tear.png',
  PHONE_MAIL = 'illustrations/phone-mail.png',
  PHONE_PLUS = 'illustrations/phone-plus.png',
  PHONE = 'illustrations/phone.png',
  POWER_PLUG = 'illustrations/power-plug.png',
  PRESENT = 'illustrations/present.png',
  ROCKET = 'illustrations/rocket.png',
  SATELLITE = 'illustrations/satellite.png',
  SCALE = 'illustrations/scale.png',
  SIGN = 'illustrations/sign.png',
  VAULT = 'illustrations/vault.png',
  VILLA = 'illustrations/villa.png',
  WAYPOINT = 'illustrations/waypoint.png',
  WID = 'illustrations/wid.png',
}

export enum IllustrationSmall {
  BEACH_CHAIR = 'illustrations/small/beach-chair.png',
  CALCULATOR = 'illustrations/small/calculator.png',
  DRESSER = 'illustrations/small/dresser.png',
  GRADUATION_CAP = 'illustrations/small/graduation-cap.png',
  HOUSE = 'illustrations/small/house.png',
  MONEY = 'illustrations/small/money.png',
  MONEY_JAR = 'illustrations/small/moneyjar.png',
  PAPER_PLANE = 'illustrations/small/paper-plane.png',
  PLAN = 'illustrations/small/plan.png',
  PLANT = 'illustrations/small/plant.png',
  SATELLITE_SIGNAL = 'illustrations/small/satellite-signal.png',
  SATELLITE = 'illustrations/small/satellite.png',
  WALLET = 'illustrations/small/wallet.png',
}

export enum IllustrationBooster {
  AUTO_INCASSO = 'illustrations/boosters/auto-incasso.png',
  CAMPAIGN_CADEAU = 'illustrations/boosters/campaign-cadeau.png',
  CAMPAIGN_TRYOUT = 'illustrations/boosters/campaign-probeer.png',
  CAMPAIGN_TRANSAVIA = 'illustrations/boosters/campaign-transavia.png',
  CAMPAIGN_GIFT = 'illustrations/boosters/present-open.png',
  REFERRAL = 'illustrations/boosters/referral.png',
  REFERRED = 'illustrations/boosters/referred.png',
  REVIEW = 'illustrations/boosters/review.png',
}

export enum SupportImage {
  CHAT = 'illustrations/support/chat.png',
  FAQ = 'illustrations/support/faq.png',
  WIKI = 'illustrations/support/wiki.png',
  BRIEFCASE = 'illustrations/support/briefcase.png',
}

import { Injectable } from '@angular/core';
import { Transaction } from '@semmie/models';
import { BaseProvider } from '@semmie/providers/_abstract/base-provider';
import { PaginatedResponse } from '@onyxx/model/pagination';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TransactionsQuery } from '@semmie/schemas/bi/transaction/transaction.query';

@Injectable()
export class TransactionsProvider extends BaseProvider<Transaction> {
  constructor() {
    super('transactions');
  }

  /**
   * List paginated account transactions
   *
   * @param query: TransactionQuery
   * @returns
   */
  list({ accountId, ...params }: TransactionsQuery): Observable<PaginatedResponse<Transaction>> {
    params.kind = params.kind instanceof Array ? (params.kind.join(',') as any) : params.kind;
    return super.__list(`accounts/${accountId}/transactions`, params).pipe(
      map((response) => {
        return {
          data: response['transactions'].map((transaction) => new Transaction(transaction)),
          meta: response.meta,
        };
      }),
    );
  }

  get(accountId: string, transactionId: string): Observable<Transaction> {
    return super.__query(`accounts/${accountId}/transactions/${transactionId}`).pipe(map((transaction) => new Transaction(transaction)));
  }
}

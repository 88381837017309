/* eslint-disable no-empty */

import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

import { postcodeValidator } from 'postcode-validator';

import getCountryISO2 from 'country-iso-3-to-2';

import { ValidatorMessage } from '@semmie/models/validators/validator-message';

export function zipcode(country: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = String(control?.value ?? '').toUpperCase();
    if (!value) return null;

    const iso2Country = getCountryISO2(country);
    let validZipcode = true;

    try {
      validZipcode = postcodeValidator(value, iso2Country);
    } catch (ex) {}

    if (!validZipcode) {
      return new ValidatorMessage({
        code: 10,
        message: 'validation.zipcode.invalid',
      });
    }

    return null;
  };
}

import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const UserCommonActions = createActionGroup({
  source: 'User Common',
  events: {
    'Update Task Count': props<{ task_count: number }>(),

    Clear: emptyProps(),
  },
});

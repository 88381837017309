import { ChangeDetectionStrategy, Component, ContentChild, EventEmitter, HostBinding, Input, Output, TemplateRef } from '@angular/core';
import { CardStyle } from '@semmie/schemas';

@Component({
  selector: 'semmie-card',
  template: `
    <div [class]="'card-container ' + getCardStyling()" (click)="onClickHandler()">
      <ng-container *ngIf="title">
        <div class="card-container-header" (click)="onHeaderClickHandler()">
          <div class="card-container-header-left">
            <ng-container [ngTemplateOutlet]="headerLeftTplRef"></ng-container>
            <semmie-label type="heading" size="h3" weight="medium" [text]="title"></semmie-label>
          </div>
          <ng-container [ngTemplateOutlet]="headerRightTplRef"></ng-container>
        </div>
      </ng-container>

      <ng-container *ngIf="!title && (headerLeftTplRef || headerRightTplRef)">
        <div class="card-container-header" (click)="onHeaderClickHandler()">
          <div class="card-container-header-left">
            <ng-container *ngIf="headerLeftTplRef" [ngTemplateOutlet]="headerLeftTplRef"></ng-container>
          </div>
          <ng-container *ngIf="headerRightTplRef" [ngTemplateOutlet]="headerRightTplRef"></ng-container>
        </div>
      </ng-container>

      <div [class]="'card-container-body ' + getStyling()">
        <ng-content></ng-content>
      </div>
    </div>
  `,
  styleUrls: ['./card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardComponent {
  @ContentChild('headerLeftTpl', { static: false }) headerLeftTplRef: TemplateRef<any>;
  @ContentChild('headerRightTpl', { static: false }) headerRightTplRef: TemplateRef<any>;

  @Input() class: string;
  @Input() cardStyle: CardStyle;
  @HostBinding('attr.title') _title = '';
  @Input() title: string;
  @Input() padding: boolean | string = true;
  @Input() rounded = 'rounded-2xl';
  @Input() disabled = false;

  @Output() onClick: EventEmitter<void> = new EventEmitter();
  @Output() onHeaderClick: EventEmitter<void> = new EventEmitter();

  getStyling() {
    const classes = new Array<string>();

    if (!this.padding) {
      classes.push('no-padding');
    } else if (this.padding && typeof this.padding === 'string') {
      classes.push(`${this.padding}`);
    } else {
      classes.push('default-padding');
    }

    if (this.class) this.class.split(' ').forEach((c) => classes.push(c));

    return classes.join(' ');
  }

  getCardStyling() {
    switch (this.cardStyle) {
      case 'neutral':
        return `${this.rounded}`;
      case 'neutralContrast':
        return `contrast ${this.rounded}`;
      default:
        return `shadow-box ${this.rounded}`;
    }
  }

  onClickHandler(): void {
    if (!this.disabled) {
      this.onClick.emit();
    }
  }

  onHeaderClickHandler(): void {
    if (!this.disabled) {
      this.onHeaderClick.emit();
    }
  }
}

import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

import { ValidatorMessage } from '@semmie/models/validators/validator-message';
import { Utils } from '@onyxx/utility/general';
import * as denotations from '@semmie/shared/validation/denotations.json';

export function minMaxLength(min: number, max: number, denotation?: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control?.value;

    if (value?.length < min || value?.length > max) {
      denotation = denotation || denotations['default'][0]; // characters
      const knownDenotation = Object.values(denotations).find((value) => (Utils.isEqual(value, denotation) ? value : undefined));
      const _denotation = Utils.isNotNil(knownDenotation) ? `validation.denotation.${knownDenotation}` : denotation;

      return new ValidatorMessage({
        code: 11,
        message: 'validation.length.minmax',
        message_data: {
          min: min,
          max: max,
          denotation: _denotation,
        },
      });
    }

    return null;
  };
}

import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

import { Utils } from '@onyxx/utility/general';
import { ValidatorMessage } from '@semmie/models/validators/validator-message';
import { utc, DurationInputArg1, DurationInputArg2 } from 'moment';

export type dateCompareType = 'before' | 'after';
export type dateCompareModifier = 'add' | 'subtract';

/**
 * Check if the is before or after a given time \
 * For Example:
 * * 'before', 'now'
 * * 'after', 'now', 'DD-MM-YYYY', 'translation.string', 'subtract', `18`, 'year'
 * * 'before', '12/12/2023', 'DD-MM-YYYY'
 * * 'before', '12/12/2023', 'DD-MM-YYYY', 'add', `2`, 'month'
 * @param compareType
 * @param compareDateTo expected in the format MM/DD/YYYY
 * @param dateFormat for display purposes
 * @param customValidation
 * @param compareModifier
 * @param amount
 * @param unit
 */
export function dateBeforeOrAfter(
  compareType: dateCompareType,
  compareDateTo: 'now' | string,
  dateFormat = 'DD-MM-YYYY',
  customValidation?: string,
  compareModifier?: dateCompareModifier,
  amount?: DurationInputArg1,
  unit?: DurationInputArg2,
): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const compareDate = compareDateTo === 'now' ? new Date() : new Date(compareDateTo);

    const datesAreValid = Utils.isValidDate(control.value) && Utils.isValidDate(compareDate);

    if (datesAreValid) {
      const value = utc(control?.value).format(dateFormat);
      const compareDate = compareDateTo === 'now' ? utc() : utc(compareDateTo);
      const nowDate = utc();

      if (compareModifier === 'add') {
        compareDate.add(amount, unit);
      } else if (compareModifier === 'subtract') {
        compareDate.subtract(amount, unit);
      }
      const isBefore = utc(value, dateFormat).isBefore(compareDate);
      const isAfter = utc(value, dateFormat).isAfter(compareDate);
      if ((compareType === 'before' && !isBefore) || (compareType === 'after' && !isAfter)) {
        return new ValidatorMessage({
          code: 16,
          message: customValidation
            ? customValidation
            : `validation.date.${compareType}.${compareDate.format(dateFormat) === nowDate.format(dateFormat) ? 'now' : 'specific'}`,
          message_data: {
            date: compareDate.format(dateFormat),
          },
        });
      }
    }
    return null;
  };
}

import { ValidationErrors, ValidatorFn } from '@angular/forms';

import { ValidatorMessage } from '@semmie/models/validators/validator-message';

export function requiredConditionalAnd(...params: Array<any>): ValidatorFn {
  return (): ValidationErrors | null => {
    if (!params) return null;

    if (!params.every((p) => !!p)) {
      return new ValidatorMessage({
        code: 1,
        message: 'validation.required',
      });
    }

    return null;
  };
}

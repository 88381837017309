import { iDynamicFormValidations } from '@semmie/schemas/components/dynamic-form/dynamic-form-validations';

export class Parser {
  static parseFunctions(functions: Array<string>): iDynamicFormValidations[] {
    if (!functions?.length) return [];

    const rules: iDynamicFormValidations[] = [];

    functions.forEach((rule) => {
      if (!rule) return;

      let ruleOnly = rule;
      let params: string[] = [];
      const hasParams = rule.indexOf('(') > -1;

      if (hasParams) {
        const beginToken = rule.indexOf('(') + 1;
        const endToken = rule.indexOf(')');

        params = rule.substr(beginToken, endToken - beginToken).split(',');
        ruleOnly = rule.substr(0, beginToken - 1);
      }

      rules.push({
        name: ruleOnly,
        params,
      });
    });

    return rules;
  }

  /**
   * Parse a currency and number string value into a number
   */
  static parseNumber(value: string): number {
    if (value === '' || value === null) {
      return 0;
    }

    const onlyRelevantValues = value.replace(/[^\d|\-|\u2212|\uFE63|\u002D|\uFF0D|,|.|\s]/g, '').trim();

    // The unicode character for a minus signs are not recognized by Number.parseInt. Therefore we replace it with a hyphen.
    // see https://en.wikipedia.org/wiki/Plus_and_minus_signs for unicode characters
    const relevantValuesWithFixesMinus = onlyRelevantValues.replace(/\u2212|\uFE63|\u002D|\uFF0D/g, '-');

    const valueParts = relevantValuesWithFixesMinus.split(/(\.|,|\s)/g);
    const numberParts = valueParts.filter((v) => !Number.isNaN(Number.parseInt(v)));

    // last separator is different (assume last separator is decimal separator)
    const separators = valueParts.filter((v) => Number.isNaN(Number.parseInt(v)));
    if (separators.length > 1) {
      const lastSeparator = separators[separators.length - 1];
      const lastSeparatorDifferent = separators.slice(0, -1).every((c) => c !== lastSeparator);
      if (lastSeparatorDifferent) {
        const numberString = numberParts.slice(0, -1).join('') + '.' + numberParts[numberParts.length - 1].slice(0, 2);
        return Number.parseFloat(numberString);
      }
    }

    // When there is one separator with more or less than three numbers after where
    // the first number is more than 0, assume the separator is a decimal separator
    if (numberParts.length === 2 && (numberParts[1].length != 3 || Number(numberParts[0]) === 0)) {
      // only one separator, make sure it's a ,
      return Number(parseFloat(numberParts.join('.')).toFixed(2));
    }

    // empty strings => 0
    if (!numberParts.join('')) {
      return 0;
    }

    // otherwise assume separators are thousands separators (and have no meaning i.t.o value)
    return Number(numberParts.join(''));
  }
}

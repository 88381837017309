import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

import { ValidatorMessage } from '@semmie/models/validators/validator-message';
import { Utils } from '@onyxx/utility/general';

export function date(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control?.value;
    if (Utils.isNil(value)) return null;

    if (!Utils.isValidDate(value)) {
      return new ValidatorMessage({
        code: 9,
        message: 'validation.date.invalid',
      });
    }

    return null;
  };
}

import { PaymentProviderEnum } from '@semmie/schemas/bi/payment/payment-provider.enum';

export const DEFAULT_PAYMENT_MODAL_SELECTION_OPTIONS = [
  {
    id: PaymentProviderEnum.ideal,
    label: 'payment.deposit-modal.options.ideal.label',
    description: 'payment.deposit-modal.options.ideal.description',
    image: 'img/payment/ideal.png',
    disabled: false,
    hidden: false,
  },
  {
    id: PaymentProviderEnum.bancontact,
    label: 'payment.deposit-modal.options.bancontact.label',
    description: 'payment.deposit-modal.options.bancontact.description',
    image: 'img/payment/bancontact.png',
    disabled: false,
    hidden: false,
  },
  {
    id: PaymentProviderEnum.manual,
    label: 'payment.deposit-modal.options.manual.label',
    description: 'payment.deposit-modal.options.manual.description',
    image: 'img/payment/manual.png',
    disabled: false,
    hidden: false,
  },
  {
    id: PaymentProviderEnum.directDebit,
    label: 'payment.deposit-modal.options.direct-debit.label',
    description: 'payment.deposit-modal.options.direct-debit.description',
    image: 'img/payment/direct-debit.png',
    route: 'direct-debit',
    disabled: false,
    hidden: false,
  },
] as const;

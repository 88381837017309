import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

import { ValidatorMessage } from '@semmie/models/validators/validator-message';
import { Utils } from '@onyxx/utility/general';
import * as denotations from '@semmie/shared/validation/denotations.json';

export function maxLength(length: number, denotation?: string, message = 'validation.length.max'): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!length) return null;

    const value = control?.value;

    if (value && value.length > length) {
      denotation = denotation || denotations['default'][0]; // characters
      const knownDenotation = Object.values(denotations).find((value) => (Utils.isEqual(value, denotation) ? value : undefined));
      const _denotation = Utils.isNotNil(knownDenotation) ? `validation.denotation.${knownDenotation}` : denotation;

      return new ValidatorMessage({
        code: 3,
        message,
        message_data: {
          length: length,
          denotation: _denotation,
        },
      });
    }

    return null;
  };
}

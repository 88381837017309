import { Injectable, inject } from '@angular/core';

import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';

import { combineLatest, filter, map } from 'rxjs';

import { Utils } from '@semmie/shared/utils';

import { UserProvider } from '@onyxx/provider/user';

import { userFeature } from './user.reducer';

import { UserAPIActions } from './actions/user-api.actions';
import { UserCommonActions } from './actions/user-common.actions';
import { iUser, iUserCredentialsPayload, UserMetaDataProperties } from '@onyxx/model/user';

@Injectable()
export class UserStoreFacade {
  readonly store = inject(Store);
  readonly actions$ = inject(Actions);
  readonly userProvider = inject(UserProvider);

  //#region Property streams

  readonly loading$ = this.store.select(userFeature.selectLoading);
  readonly loaded$ = this.store.select(userFeature.selectLoaded);
  readonly user$ = this.store.select(userFeature.selectUser);

  /** Count of all the task kinds together */
  readonly taskCountCombined$ = this.store.select(userFeature.selectTaskCountCombined);
  readonly taskCountCritical$ = this.store.select(userFeature.selectTaskCountCritical);
  readonly taskCountHigh$ = this.store.select(userFeature.selectTaskCountHigh);
  readonly taskCountNormal$ = this.store.select(userFeature.selectTaskCountNormal);

  readonly openRewardCount$ = this.store.select(userFeature.selectOpenRewardCount);
  readonly userAccountCounter$ = this.store.select(userFeature.selectUserAccountCounter);
  readonly unreadInboxCount$ = this.store.select(userFeature.selectUnreadInboxCount);
  readonly updating$ = this.store.select(userFeature.selectUpdating);
  readonly credentialsUpdating$ = this.store.select(userFeature.selectCredentialsUpdating);
  readonly isAdvisor$ = this.store.select(userFeature.selectUserIsAdvisor);

  readonly twoFactorUpdateBusy$ = this.store.select(userFeature.selectTwoFactorUpdateBusy);
  readonly twoFactorEnabled$ = this.store.select(userFeature.selectUserTwoFactorEnabled);
  readonly twoFactorResendAllowedAt$ = this.store.select(userFeature.selectTwoFactorResendAllowedAt);
  /** Event when updating two factor authentication fails */
  readonly twoFactorAuthenticationUpdateFailure$ = this.actions$.pipe(ofType(UserAPIActions.updateTwoFactorAuthenticationFailure));
  /** Event when updating two factor authentication succeeds */
  readonly twoFactorAuthenticationUpdateSuccess$ = this.actions$.pipe(ofType(UserAPIActions.updateTwoFactorAuthenticationSuccess));

  /** If initialized, returns `true` when the user is not loading and was loaded successfully */
  readonly ready$ = this.store.select(userFeature.selectIsReady);
  readonly readyNotification$ = this.ready$.pipe(filter(Boolean));

  /**
   * @deprecated {@link UserStoreFacade.user$ | `user$` is preferred }
   * This is here for for legacy reasons, (open task service) until its rewritten to use effects. DO NOT use this, it will be removed
   * */
  readonly latestUser$ = combineLatest([this.user$.pipe(filter(Utils.isNonNullOrUndefined)), this.readyNotification$]).pipe(
    map(([user]) => user),
  );

  //#endregion

  // Events
  readonly updateSuccess$ = this.actions$.pipe(ofType(UserAPIActions.updateSuccess));
  readonly loadDone$ = this.actions$.pipe(ofType(UserAPIActions.loadSuccess, UserAPIActions.loadFailure));
  readonly updateCredentialsSuccess$ = this.actions$.pipe(ofType(UserAPIActions.updateCredentialsSuccess));
  readonly updateCredentialsFailure$ = this.actions$.pipe(ofType(UserAPIActions.updateCredentialsFailure));

  //#region Actions

  load() {
    this.store.dispatch(UserAPIActions.load());
  }

  update(user: Partial<iUser>) {
    this.store.dispatch(UserAPIActions.update({ user }));
  }

  setPremiumThemeEnabled(premiumThemeEnabled: boolean) {
    const newData = {};
    newData[UserMetaDataProperties.PremiumThemeEnabled] = premiumThemeEnabled;
    this.store.dispatch(UserAPIActions.updateMetadata({ newData }));
  }

  /**
   * @deprecated: TODO: investigate why do we actually perform updates on the state locally. Should not be required.
   * Bitte not use deze
   * */
  updateUserStore(user: Partial<iUser>) {
    this.store.dispatch(UserAPIActions.localUpdate({ user }));
  }

  updateCredentials(payload: iUserCredentialsPayload) {
    this.store.dispatch(UserAPIActions.updateCredentials({ payload }));
  }

  //#endregion

  /**
   * Returns the users name and its origin
   *
   * @param token password reset token
   * @returns iUserTokenDetails
   */
  getUserTokenDetailsFromToken(token: string) {
    return this.userProvider.getUserTokenDetailsFromToken(token);
  }

  dispatchSendTwoFactorAuthenticationSms() {
    this.store.dispatch(UserAPIActions.sendTwoFactorAuthenticationCode());
  }

  dispatchEnableTwoFactorAuthentication(code: string) {
    this.store.dispatch(UserAPIActions.updateTwoFactorAuthentication({ enabled: true, code }));
  }

  dispatchDisableTwoFactorAuthentication() {
    this.store.dispatch(UserAPIActions.updateTwoFactorAuthentication({ enabled: false }));
  }

  dispatchUpdateTaskCounts(task_count: number) {
    this.store.dispatch(UserCommonActions.updateTaskCount({ task_count }));
  }
}

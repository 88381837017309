import { ChangeDetectionStrategy, Component, computed, inject, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalScrollModule } from '@semmie/components/containers/modals/modal-scroll/modal-scroll.component';
import { ButtonModule, CarouselModule, CarouselSlideModule, ContentContainerModule, ImageModule, LabelModule } from '@semmie/components';
import { ModalService, PlatformService } from '@semmie/services';
import { StickyCloseModule } from '@semmie/components/presentational/core/sticky-close/sticky-close.module';
import { ContentIntroTextComponent } from '@semmie/components/containers/page-content/content-intro-text/content-intro-text.component';
import { BackgroundImageDirective } from '@semmie/directives/background-image.directive';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { HighlightsFacade } from '@onyxx/store/highlights';
import { toSignal } from '@angular/core/rxjs-interop';
import { map } from 'rxjs';

@Component({
  selector: 'onyxx-highlights-modal',
  standalone: true,
  imports: [
    CommonModule,
    ModalScrollModule,
    StickyCloseModule,
    ContentContainerModule,
    LabelModule,
    ContentIntroTextComponent,
    ImageModule,
    ButtonModule,
    CarouselModule,
    CarouselSlideModule,
    BackgroundImageDirective,
  ],
  template: `<semmie-modal-scroll *ngIf="modalSizeIsFull$ | async; else highlightsModalContent">
      <semmie-modal-scroll-content>
        <ng-container *ngTemplateOutlet="highlightsModalContent"></ng-container>
      </semmie-modal-scroll-content>
    </semmie-modal-scroll>

    <ng-template #highlightsModalContent>
      <semmie-content-container padding="px-0">
        <semmie-carousel
          [slidesPerView]="1"
          [spaceBetween]="0"
          [navigation]="false"
          [centeredSlides]="true"
          [pagination]="{ enabled: true }"
          [activeIndex]="activeSlideIndex$$()"
          (activeSlideChanged)="highlightSlideChanged($event)"
        >
          @for (highlightSlide of highlights$$().slides; track $index) {
            <semmie-carousel-slide>
              <ng-template #carouselSlideContent>
                @if (highlightSlide.image) {
                  <div class="highlight-image" [semmieBackgroundImage]="highlightSlide.image"></div>
                } @else {
                  <div class="highlight-image"></div>
                }
                <div class="highlight-content">
                  <semmie-label type="heading" align="center" size="h2" weight="regular" [text]="highlightSlide.title"></semmie-label>
                  <semmie-content-intro-text [htmlContent]="highlightSlide.description"></semmie-content-intro-text>
                </div>
              </ng-template>
            </semmie-carousel-slide>
          }
        </semmie-carousel>

        <semmie-content-container-bottom>
          @if (isLastSlide$$()) {
            <semmie-button (onClick)="closeHighlightsModal()" i18n="@@highlights-modal.close">Close</semmie-button>
          } @else {
            <semmie-button (onClick)="nextHighlightSlide()" i18n="@@highlights-modal.next">Next</semmie-button>
          }
        </semmie-content-container-bottom>
      </semmie-content-container>
    </ng-template>

    <semmie-sticky-close (onClick)="closeHighlightsModal()"></semmie-sticky-close>`,
  styleUrls: ['./highlights-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HighlightsModalComponent {
  private readonly highlightsFacade = inject(HighlightsFacade);
  private readonly modalService = inject(ModalService);
  private readonly platformService = inject(PlatformService);

  readonly modalSizeIsFull$ = this.platformService.appLayout$;

  readonly highlights$$ = toSignal(
    this.highlightsFacade.highlights$.pipe(
      map((highlights) => {
        const slides = highlights?.flatMap((highlights) => highlights.slides) ?? [];
        return { slides, totalSlides: slides?.length ?? 0 };
      }),
    ),
    { initialValue: { slides: [], totalSlides: 0 } },
  );

  readonly isLastSlide$$ = computed(() => {
    if (this.highlights$$().totalSlides === 1 || this.activeSlideIndex$$() + 1 === this.highlights$$().totalSlides) {
      return true;
    } else {
      return false;
    }
  });

  activeSlideIndex$$ = signal(0);

  nextHighlightSlide() {
    this.activeSlideIndex$$.update((value) => value + 1);
  }

  highlightSlideChanged(activeSlide: number) {
    this.activeSlideIndex$$.set(activeSlide);
  }

  closeHighlightsModal() {
    this.modalService.close();
  }
}

import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

import { ValidatorMessage } from '@semmie/models/validators/validator-message';
import { SemmieCurrencyPipe } from '@semmie/pipes/currency/currency.pipe';
import { Utils } from '@onyxx/utility/general';
import * as denotations from '@semmie/shared/validation/denotations.json';

import { Formatter } from '../validator-formatter';

@Injectable({ providedIn: 'root' })
export class RangeValidator {
  constructor(private semmieCurrencyPipe: SemmieCurrencyPipe) {}

  range(
    min: string | number,
    max: string | number,
    property?: string,
    denotation?: string,
    formatter?: Formatter,
    errorMessageKey = 'validation.range.default',
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = property ? control?.value[property] : control?.value;

      const numericValue = parseFloat(value);

      if (numericValue < Number(min) || numericValue > Number(max)) {
        denotation = denotation || denotations['default'][7]; // number
        const knownDenotation = Object.values(denotations).find((value) => (Utils.isEqual(value, denotation) ? value : undefined));
        const _denotation = Utils.isNotNil(knownDenotation) ? `validation.denotation.${knownDenotation}` : denotation;

        switch (formatter) {
          case Formatter.CURRENCY:
            min = this.semmieCurrencyPipe.transform(min);
            max = this.semmieCurrencyPipe.transform(max);
            break;
        }

        return new ValidatorMessage({
          code: 7,
          message: errorMessageKey,
          message_data: {
            min,
            max,
            denotation: _denotation,
          },
        });
      }

      return null;
    };
  }
}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { of } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';

import { NavigationService } from '@semmie/services';
import { OpenTasksService } from '@semmie/services/open-tasks';

import { Utils } from '@onyxx/utility/general';
import { UserStoreFacade } from '@semmie/store/user';
import { AppRouteNames } from '@onyxx/model/main';

@Injectable()
export class CriticalTaskGuard {
  constructor(
    private userFacade: UserStoreFacade,
    private openTasksService: OpenTasksService,
    private navService: NavigationService,
  ) {}

  canActivate(_: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.userFacade.taskCountCritical$.pipe(
      filter(Utils.isNotNil),
      take(1),
      switchMap((taskCountCritical) => {
        if (taskCountCritical > 0) {
          return this.openTasksService.taskToBeHandled$.pipe(
            map((taskVM) => {
              if (Utils.isNil(taskVM)) return true;
              if (
                (taskVM.redirectUrl?.length && state.url.startsWith(this.navService.createUrlTree(taskVM.redirectUrl).toString())) ||
                taskVM.allowedUrls.some((url) => state.url.startsWith(this.navService.createUrlTree(url).toString()))
              )
                return true;
              return this.navService.createUrlTree([AppRouteNames.CriticalTasks]);
            }),
          );
        }
        return of(true);
      }),
    );
  }
}

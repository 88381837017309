export enum AccountTaskName {
  CONFIGURE_STRATEGY = 'configure_strategy',
  CONFIGURE_GOAL = 'configure_goal',
}

export enum UserTaskName {
  // TODO: Extend this to all user task names
  PHONE_VERIFICATION = 'phone_verification',
  CDD_PERSON = 'cdd_person_re_examination',
}

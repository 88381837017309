import { Injectable } from '@angular/core';

import { EMPTY, Observable, combineLatest, map, of, switchMap, take } from 'rxjs';

import { BaseOnboardingService } from './__abstract/base-onboarding.service';
import { Goal, Person } from '@semmie/models';
import { Account, AccountHelpers } from '@onyxx/model/account';
import { iUser } from '@onyxx/model/user';
import { OnboardingRouteNames } from '@semmie/views/onboarding/onboarding.common';
import { MainRouteNames } from '@onyxx/model/main';

@Injectable({
  providedIn: 'root',
})
export class AnnuityOnboardingService extends BaseOnboardingService {
  override initialize(account: Account, person?: Person | undefined, user?: iUser | undefined, goal?: Goal | undefined): void {
    super.initialize(account, person, user, goal);
  }

  override handleOnboarding(): void {
    this.hasFinishedAccount()
      .pipe(
        switchMap((finished) => this.handleFinishedAccount(finished)),
        switchMap((finished) => this.handleFinishedPerson(finished)),
        switchMap((finished) => this.handleFinishedIdentification(finished)),
        switchMap((finished) => this.handleFinishedQuestionnaire(finished)),
        switchMap((finished) => this.handleFinishedStrategy(finished)),
        switchMap((finished) => this.handleFinishedPlan(finished)),
        switchMap((finished) => this.handleFinishedFirstPayment(finished)),
        switchMap((finished) => this.handleFinishedSummary(finished)),
        switchMap((finished) => this.handleFinishedSigning(finished)),
      )
      .subscribe(() => {
        this.navigationService.navigate([
          this.onboardingPathPrefix,
          MainRouteNames.Onboarding,
          this.account.id,
          OnboardingRouteNames.AccountCreated,
        ]);
      });
  }

  override handleFinishedStrategy(finished: boolean) {
    if (!finished) {
      this.currentStep.next('strategy');

      this.navigationService.navigate([
        this.onboardingPathPrefix,
        MainRouteNames.Onboarding,
        this.account.id,
        OnboardingRouteNames.Strategy,
      ]);

      return EMPTY;
    }

    return this.hasFinishedAccountPlan();
  }

  override handleFinishedPlan(finished: boolean) {
    if (!finished) {
      this.currentStep.next('plan');

      this.navigationService.navigate([
        this.onboardingPathPrefix,
        MainRouteNames.Onboarding,
        this.account.id,
        OnboardingRouteNames.ValueTransfer,
        ...(AccountHelpers.isAnnuityPayout(this.account) ? ['overview'] : []),
      ]);

      return EMPTY;
    }

    return this.hasFinishedFirstPayment();
  }

  override handleFinishedFirstPayment(finished: boolean) {
    if (!finished) {
      this.currentStep.next('payment');

      this.navigationService.navigate([
        this.onboardingPathPrefix,
        MainRouteNames.Onboarding,
        this.account.id,
        OnboardingRouteNames.Payment,
        { step: AccountHelpers.isAnnuityPayout(this.account) ? 'wid' : 'first' },
      ]);

      return EMPTY;
    }

    return this.hasFinishedSummary();
  }

  override hasFinishedAccountPlan(): Observable<boolean> {
    const finishedPlan$ = of(AccountHelpers.hasFinishedPlan(this.account));
    if (AccountHelpers.isAnnuityPayout(this.account)) {
      const payoutDefined$ = this.payoutFacade.loadActionDone$.pipe(
        switchMap(() => this.payoutFacade.payout$),
        take(1),
        map((payout) => !!payout),
      );

      this.payoutFacade.dispatchLoad();
      return combineLatest([payoutDefined$, finishedPlan$]).pipe(map(([payoutDefined, finishedPlan]) => finishedPlan && payoutDefined));
    }

    return finishedPlan$;
  }
}

import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

import { ValidatorMessage } from '@semmie/models/validators/validator-message';

export function requiredTrue(customValidation?: string, property?: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = property ? control?.value[property] : control?.value;

    if (value !== true) {
      return new ValidatorMessage({
        code: 1,
        message: customValidation ? customValidation : 'validation.requiredTrue',
      });
    }

    return null;
  };
}

import { Component, EventEmitter, Output } from '@angular/core';

import { Icon } from '@semmie/schemas';
import { BaseComponent } from '@semmie/components/_abstract';

@Component({
  selector: 'semmie-sticky-close',
  template: `
    <button (click)="onClickHandler($event)" class="close-button">
      <semmie-icon size="xxs" [icon]="Icon.CLOSE"></semmie-icon>
    </button>
  `,
  styleUrls: ['./sticky-close.component.scss'],
})
export class StickyCloseComponent extends BaseComponent {
  @Output() onClick: EventEmitter<any> = new EventEmitter();

  readonly Icon = Icon;

  constructor() {
    super();
  }

  onClickHandler($event: any): void {
    this.onClick.emit($event);
  }
}

import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

import { ValidatorMessage } from '@semmie/models/validators/validator-message';
import { RegExpPatterns } from '@semmie/shared/regexp';

export function noNumbers(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control?.value;

    const containsNumbers = RegExpPatterns.NUMBERS.test(value);

    if (containsNumbers) {
      return new ValidatorMessage({
        code: 5,
        message: 'validation.numbers.no_numbers',
      });
    }

    return null;
  };
}

import { ChangeDetectorRef, Component, Host, Inject, Input, LOCALE_ID, OnInit, Optional, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, ValidationErrors } from '@angular/forms';

import { startWith } from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';

import mustache from 'mustache';

import { FormGroupComponent } from '@semmie/components/containers/form-group/form-group.component';
import { FormComponent } from '@semmie/components/containers/form/form.component';
import { UploadComponent } from '@semmie/components/presentational/core/upload/upload.component';
import { BaseComponent } from '@semmie/components/_abstract';
import { DynamicField, iDropdownFormField, iGroupFormField, iRadioButtonGroupFormField } from '@semmie/schemas/components/dynamic-form';
import { DynamicFieldType } from '@semmie/schemas/components/dynamic-form/dynamic-form-types';
import { iExternalDataFormField } from '@semmie/schemas/components/dynamic-form/form-fields';
import { FormService } from '@semmie/services/form/form.service';
import { JmespathService } from '@semmie/services/jmespath/jmespath.service';
import { Parser } from '@semmie/shared/parser';
import { InterpolateService } from '@semmie/services/interpolate/interpolate.service';
import { iInfoModal } from '@semmie/schemas/components/modal/info-modal.interface';
import { Utils } from '@onyxx/utility/general';
import { InfoModalComponent } from '@semmie/components/containers/modals/info-modal/info-modal.component';
import { ModalService, NavigationService } from '@semmie/services';
import { ExternalDataComponent } from '@semmie/components/presentational/core/external-data/external-data.component';
import { ActivatedRoute } from '@angular/router';
import { DynamicFormFieldEvent } from '@semmie/schemas/components/dynamic-form/dynamic-form-event';
import { Icon } from '@semmie/schemas';
import { ModalSize } from '@semmie/schemas/components/modal';
import { FormModalType } from '@semmie/schemas/components/modal/form/form-modal-type.enum';
import { Illustration } from '@semmie/shared/globals';
import { iDynamicFormValidations } from '@semmie/schemas/components/dynamic-form/dynamic-form-validations';
import { TextboxComponent } from '@semmie/components/presentational/core/textbox/textbox.component';
import { CoverImageSelectorComponent } from '@semmie/components/presentational/core/cover-image-selector/cover-image-selector.component';

@Component({
  selector: 'semmie-form-input',
  templateUrl: './form-input.component.html',
  styleUrls: ['./form-input.component.scss'],
})
export class FormInputComponent extends BaseComponent implements OnInit {
  /**
   * Reference to all Upload fields.
   *
   * !todo: refactor later so that it works for all component types
   */
  @ViewChild(UploadComponent) uploadComponent: UploadComponent;

  /**
   * Reference to all cover image selector controls
   *
   * !todo: refactor later so that it works for all component types
   */
  @ViewChild(CoverImageSelectorComponent) coverImageSelector: CoverImageSelectorComponent;

  /**
   * Reference to all ExternalData fields.
   *
   * !todo: refactor later so that it works for all component types
   */
  @ViewChild(ExternalDataComponent) externalDataComponent: ExternalDataComponent;

  /**
   * !todo: temp exposing the textbox to access nativeElement in login.component.ts and register.component.ts
   */
  @ViewChild(TextboxComponent) textboxComponent: TextboxComponent;

  /**
   * The field index
   *
   * Currently used in 'repeat' groups.
   */
  @Input() index: number;

  /**
   * The control associated to the field.
   *
   * Currently only used from a 'repeat' group.
   */
  @Input() control: UntypedFormControl;

  /**
   * A reference to the FormGroup, used in 'special' field types such as:
   *  - carousel
   *  - graph
   *  - external-data
   *  - auto-complete
   */
  @Input() form: UntypedFormGroup;

  /**
   * A reference to the group field.
   *
   * Only used when the host field is a group.
   */
  @Input() group: iGroupFormField;

  /**
   * The field definition.
   */
  @Input() field: DynamicField;

  /**
   * The error associated with the field.
   */
  @Input() error: ValidationErrors | string | undefined | null;

  /**
   * Classes that should be applied to the hosting component.
   *
   * Currently only used in the semmie-label.
   */
  @Input() classes: string;

  @Input() set enableSideSpacing(enableSideSpacing: boolean) {
    if (!enableSideSpacing) return;
    switch (this.field.type) {
      case DynamicFieldType.Text:
      case DynamicFieldType.Dropdown:
      case DynamicFieldType.Date:
      case DynamicFieldType.Slider:
        this.sideSpacingClasses = 'block px-8';
        break;
      case DynamicFieldType.CarouselSelect:
        this.sideSpacingClasses = 'block px-0';
        break;
      default:
        this.sideSpacingClasses = 'block px-4';
        break;
    }
  }

  @Input() $meta: Record<string, any> = {};

  Icon = Icon;
  sideSpacingClasses: string;

  /**
   * An internal copy of the field definition to be manipulated.
   */
  internalField: DynamicField;

  infoModal?: iInfoModal;
  readonly DynamicFieldType = DynamicFieldType;

  /**
   * A list of calculated field validations.
   */
  private rules: Array<iDynamicFormValidations>;

  constructor(
    @Optional() @Host() private formComponent: FormComponent,
    @Optional() @Host() private formGroup: FormGroupComponent,
    private activatedRoute: ActivatedRoute,
    private formService: FormService,
    private jmesPathService: JmespathService,
    private modalService: ModalService,
    private navigationService: NavigationService,
    private translateService: TranslateService,
    private interpolateService: InterpolateService,
    public cdr: ChangeDetectorRef,
    @Inject(LOCALE_ID) public locale: string,
  ) {
    super();
  }

  ngOnInit(): void {
    this.internalField = JSON.parse(JSON.stringify(this.field));
    this.rules = Parser.parseFunctions(this.internalField?.validations);

    this.form.valueChanges.pipe(startWith(this.form.value)).subscribe((newVal) => {
      if (!this.meetsConditions(this.field.conditions)) return;

      switch (this.field.type) {
        case DynamicFieldType.Dropdown:
          this.parseDropdown(newVal);
          break;
        case DynamicFieldType.Radio:
          this.parseRadio(newVal);
          break;
        case DynamicFieldType.Upload:
          this.parseUpload(newVal);
          break;
        case DynamicFieldType.CoverImage:
          this.parseCoverImage();
          break;
        case DynamicFieldType.Text:
          this.parseCountryCode();
          break;
      }
    });

    this.setInfoModal();
  }

  /**
   * Returns the FormComponent, even if called from a group context.
   */
  get actualFormComponent(): FormComponent {
    if (this.formGroup) {
      return this.formGroup.formComponent;
    }

    return this.formComponent;
  }

  get infoModals(): iInfoModal[] {
    return this.actualFormComponent?.dynamicForm?.infoModals ?? [];
  }

  /**
   * Returns whether the field has errors or not by checking whether an error was passed
   * or if the control is invalid.
   *
   * @returns boolean - result of whether a control is invalid
   */
  hasErrors(): boolean {
    if (this.error) {
      return true;
    }

    const ctrl = this.getFormControl(this.internalField);

    if (Utils.isNil(ctrl)) return false;

    const showEarlyValidation = Boolean(this.interpolateFieldProperty('showEarlyValidation'));

    return ctrl.invalid && (ctrl.touched || ctrl.dirty || showEarlyValidation);
  }

  /**
   * Returns the error of the field.
   *
   * @returns string | null - an error message
   */
  getErrors(): string | undefined {
    const ctrl = this.getFormControl(this.internalField);

    const showEarlyValidation = Boolean(this.interpolateFieldProperty('showEarlyValidation'));

    if (ctrl?.errors && (ctrl.touched || showEarlyValidation)) {
      const messageData = this.handleErrorMessageData(ctrl.errors.message_data);
      return this.translateService.instant(ctrl.errors.message, messageData || this.form.getRawValue());
    }

    /**
     * !dev note: For some (yet) unknown reason this.error could be an object.
     * It's an edge case which caused by an error from from-group.component.
     * Which results an error with the interpolation at content() in the (error)label component.
     */
    if (this.error && typeof this.error === 'string') {
      return this.error;
    }
  }

  /**
   * Handles translations for validations message data.
   *
   * @param data any
   * @returns translated message data
   */
  handleErrorMessageData(data: any): any {
    if (Utils.isNil(data)) return;
    if (Utils.isObject(data)) {
      Object.keys(data).forEach((key) => {
        data[key] = typeof data[key] === 'string' ? this.translateService.instant(data[key]) : data[key];
      });
      return data;
    } else {
      return this.translateService.instant(data);
    }
  }

  onBlur(): void {
    if (this.error) {
      this.actualFormComponent.setErrorMessage(this.internalField.name);
    }
  }

  /**
   * Check if field conditions are met by comparing the expression(s) to the data.
   *
   * @param conditions an Array of string expressions
   * @returns boolean - the result of the expression(s)
   */
  meetsConditions(conditions?: Array<string>): boolean {
    const formData = this.group ? this.formGroup?.formComponent?.data : this.formComponent?.data;

    return this.formService.meetsConditions(conditions, {
      ...(formData ?? {}),
      ...this.form?.getRawValue(),
      $: this.field.$ ? this.field?.$[this.index] : {},
    });
  }

  conditionValueChanged(conditions?: Array<string>, prev?: any, curr?: any): boolean {
    return this.formService.conditionValueChanged(conditions, prev, curr);
  }

  /**
   * Fired when a change gets triggered from the ExternalDataComponent.
   *
   * @param params data: any, pristine?: boolean, loading: boolean
   */
  onExternalDataChange(params: { data: any; pristine?: boolean; loading: boolean }): void {
    const data = params.data ?? {};
    const pristine = params.pristine ?? this.form.pristine;

    const flatData = {};
    const field: iExternalDataFormField = this.internalField;

    const ctrl = this.getFormControl(this.internalField);
    this.error = null;
    ctrl.setErrors(null);

    if (field.flat) {
      for (const key in data) {
        Object.assign(flatData, data[key]);
      }
    } else {
      Object.assign(flatData, { [field.name]: data });
    }

    this.actualFormComponent.updateFormValue(flatData, !field.flat, pristine, { [field.name]: { loading: params.loading } });
  }

  /**
   * Fired when a change in loading state gets triggered from the ExternalDataComponent.
   */
  onExternalLoadingChange(loading: boolean): void {
    const field: iExternalDataFormField = this.internalField;
    this.actualFormComponent.$meta = { ...this.actualFormComponent.$meta, ...{ [field.name]: { loading } } };
  }

  onFormFieldInteraction(event: DynamicFormFieldEvent) {
    this.actualFormComponent.formEvents$.next(event);
  }

  async openInfoModal(): Promise<void> {
    if (!this.infoModal) return;

    await this.modalService.open(
      InfoModalComponent,
      {
        componentProps: {
          ...this.infoModal,
        },
      },
      { size: ModalSize.Auto },
    );
  }

  /**
   * Triggered when an ExternalData field catches an error.
   *
   * @param HttpResponse - errors (needs to be set to the right type)
   */
  onExternalDataError(errors: any): void {
    this.error = errors;
    const ctrl = this.getFormControl(this.internalField);
    ctrl.setErrors(errors);
  }

  /**
   * Returns the FormControl.
   *
   * @param field a reference to a dynamic field
   * @returns the control
   */
  getFormControl(field: DynamicField): UntypedFormControl {
    return this.control ?? this.formService.getFormControl(field, this.form, this.group);
  }

  /**
   * Returns the field type, which may remap types to render the appropriate field component.
   *
   * @returns the field type
   */
  getFieldType(): string {
    return this.formService.getFieldType(this.internalField);
  }

  /**
   * Returns the field hint.
   *
   * @returns the field hint or null
   */
  getFieldLabelHint(): string | null {
    let hint: number | null = null;

    this.rules?.forEach((r) => {
      const interpolatedParams = r.params?.map((p) => {
        if (typeof p === 'number') return p;
        return this.jmesPathService.interpolate(this.getFormValueAndData(), String(p));
      });

      if (r.name === 'maxLength') {
        if (interpolatedParams?.[0]) {
          const remaining = parseInt(interpolatedParams[0] as string) - (this.getFormControl(this.internalField)?.value?.length || 0);
          hint = Math.max(remaining, 0);
        }
      }
    });

    return hint;
  }

  /**
   * Returns whether the current field can show an error message underneath the field.
   * @returns boolean
   */
  fieldCanShowErrors(): boolean {
    return ![
      DynamicFieldType.Image,
      DynamicFieldType.Label,
      DynamicFieldType.Toggle,
      DynamicFieldType.Group,
      DynamicFieldType.ExternalData,
      DynamicFieldType.Static,
      DynamicFieldType.Upload,
      DynamicFieldType.AutocompleteList,
      DynamicFieldType.InputCard,
      DynamicFieldType.Modal,
      DynamicFieldType.Checkbox,
      DynamicFieldType.Radio,
    ].includes(this.internalField.type);
  }

  navigateTo(path: string): void {
    if (!path) return;
    this.navigationService.navigate([path], { relativeTo: this.activatedRoute });
  }

  /**
   * Translate and interpolate the field property.
   *
   * Used by the InfoModals.
   *
   * !todo: deprecate this in favor of 'interpolateFieldProperty'
   *
   * @param property property to translate
   * @returns translated and interpolated property
   */
  translateFieldProperty(property: string): string {
    return this.formService.translateFieldProperty(property, this.field, {
      ...(this.formComponent?.data ?? {}),
      ...this.form.getRawValue(),
      $: this.field.$ ? this.field?.$[this.index] : {},
      $meta: this.$meta,
    });
  }

  /**
   * Translate and interpolate the field property.
   *
   * @param property property to interpolate
   * @returns translated or interpolated property
   */
  interpolateFieldProperty(property: string) {
    return this.formService.interpolateFieldProperty(property, this.field, {
      ...(this.formComponent?.data ?? {}),
      ...this.form.getRawValue(),
      $: this.field.$ ? this.field?.$[this.index] : {},
      $meta: this.$meta,
    });
  }

  /**
   * Render a string with Mustache expressions.
   *
   * @param str the string to be interpolated and rendered
   * @returns an interpolated string
   */
  renderString(str: string) {
    return this.interpolateService.renderString(str, this.form.getRawValue());
  }

  renderPropertyWithInfoModals(property: string): string {
    return this.interpolateService.renderWithInfoModals(this.translateFieldProperty(property), {}, this.infoModals);
  }

  /**
   * Parse the upload data and sets the field params
   *
   * @param newVal value to use for the upload field params.
   */
  parseUpload(newVal: any): void {
    const field = this.field;

    const url = `${field.url}`;
    const params: any = { ...field.params };

    if (url) {
      const interpolatedUrl = mustache.render(url, this.form.value);
      this.internalField.url = interpolatedUrl;
    }

    for (const key in params) {
      const val = params[key];

      if (typeof val === 'object') {
        const srcExpression = val.source;
        const interpolatedValue = this.jmesPathService.interpolate(newVal, srcExpression);
        this.internalField.params[key] = interpolatedValue;
      }
    }

    if (field.uploadAfterCreation) {
      if (typeof field.uploadAfterCreation === 'string') {
        this.internalField.uploadAfterCreation = this.jmesPathService.interpolate(this.form.value, field.uploadAfterCreation);
      }
    }
  }

  /**
   * Parse the dropdown definition and generate its options.
   *
   * @param newVal value to use when generating the dropdown options
   */
  private parseDropdown(newVal: any): void {
    const field = this.field as iDropdownFormField;
    const internalField = this.internalField as iDropdownFormField;

    if (typeof field.options === 'string') {
      internalField.options = newVal[field.options][0];
    } else if (typeof field.options === 'object') {
      if (Array.isArray(field.options)) {
        let options = field.options;

        if (Array.isArray(options)) {
          options = options.filter((option) => {
            if (option.conditions) {
              return this.meetsConditions(option.conditions);
            }

            return true;
          });
        }
        internalField.options = options;
      } else {
        const opts: any = { ...field.options };

        if (opts.source) {
          const interpolatedSource = mustache.render(opts?.source, newVal) ?? '0';

          const sourceValue: any = this.jmesPathService.interpolate(newVal, interpolatedSource);

          if (!sourceValue || !Array.isArray(sourceValue)) return;

          const normalizedData = sourceValue.map((v) => {
            if (v.options?.warning) {
              this.createWarningForQuestionValue(v.options.warning, internalField.name, v.value);
            }
            return {
              label: this.jmesPathService.interpolate(v, opts.label) ?? v,
              value: this.jmesPathService.interpolate(v, opts.value) ?? v,
              image: opts?.image ? mustache.render(opts.image, v).toLowerCase() : null,
              imageStyle: opts?.imageStyle ?? null,
              deprecated: v.deprecated ?? false,
            };
          });

          internalField.options = this.formService.parseProjections(normalizedData, opts?.projection);
        }
      }
    }
  }

  /**
   * Parse the radio definition and generate its options.
   *
   * @param newVal value to use when generating the dropdown options
   */
  private parseRadio(newVal: any): void {
    const field = this.field as iRadioButtonGroupFormField<typeof this.field.value>;
    const internalField = this.internalField as iDropdownFormField;

    if (typeof field.options === 'object') {
      if (!Array.isArray(field.options)) {
        const opts: any = { ...field.options };

        if (opts.source) {
          const interpolatedSource = mustache.render(opts?.source, newVal) ?? '0';

          const sourceValue: any = this.jmesPathService.interpolate(newVal, interpolatedSource);

          if (!sourceValue || !Array.isArray(sourceValue)) return;

          const normalizedData = sourceValue.map((v) => {
            return {
              label: this.jmesPathService.interpolate(v, opts.label) ?? v,
              value: this.jmesPathService.interpolate(v, opts.value) ?? v,
              description: this.jmesPathService.interpolate(v, opts.description) ?? v,
              cardLabel: this.jmesPathService.interpolate(v, opts.cardLabel) ?? v,
            };
          });

          internalField.options = this.formService.parseProjections(normalizedData, opts?.projection);
        }
      }
    }
  }

  /**
   * Parse the cover image select component data
   */
  private parseCoverImage(): void {
    const field = this.field;

    const url = `${field.url}`;

    if (url) {
      const interpolatedUrl = mustache.render(url, this.form.value);
      this.internalField.url = interpolatedUrl;
    }
  }

  /**
   * Parse the country code
   */
  private parseCountryCode(): void {
    const field = this.field;

    const countryCode = `${field.countryCode}`;

    if (countryCode) {
      const interpolatedCountryCode = mustache.render(countryCode, this.form.value);
      this.internalField.countryCode = interpolatedCountryCode;
    }
  }

  /**
   * Find the info modal in the form settings by the corresponding id
   */
  private setInfoModal(): void {
    if (this.field.infoModal) {
      this.infoModal = this.infoModals?.find((m) => m.id === this.field.infoModal);
    }
  }

  private createWarningForQuestionValue(warning: { title: string; description: string }, question: string, value: any): void {
    if (!this.actualFormComponent?.dynamicForm) return;
    const id = `${question}_warning_${value.toString()}`;
    if (!this.actualFormComponent.dynamicForm.infoModals) {
      this.actualFormComponent.dynamicForm.infoModals = [];
    }
    if (!this.actualFormComponent.dynamicForm.infoModals.find((m) => m.id === id)) {
      this.actualFormComponent.dynamicForm.infoModals.push({
        id,
        title: warning.title,
        description: warning.description,
        image: Illustration.CONE,
        button: this.translateService.instant('core.common.labels.understood'),
      });
      this.actualFormComponent.dynamicForm.fields.push({
        name: id,
        type: DynamicFieldType.Modal,
        showOn: 'submit',
        modalType: FormModalType.INFO,
        infoModal: id,
        conditions: [`${question} === \`${value}\``],
      } as DynamicField);
    }
  }

  /**
   * Returns the form value and data.
   *
   * @returns form value and data
   */
  private getFormValueAndData() {
    return this.group ? this.formGroup?.formComponent?.getFormValueAndData() : this.formComponent?.getFormValueAndData();
  }
}

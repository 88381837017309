import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { BehaviorSubject, combineLatest, firstValueFrom, of } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  shareReplay,
  skipUntil,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

import { Task } from '@semmie/models/bi/task';
import { TaskKind, TaskPriority, UserTaskName } from '@onyxx/model/task';
import { ModalService } from '@semmie/services/modal/modal.service';
import { NavigationService } from '@semmie/services/navigation/navigation.service';
import { TasksStore } from '@semmie/store/tasks';

import { OnyxxImage, Illustration } from '@semmie/shared/globals';

import { AccountStatusModalComponent } from '@semmie/components';
import { AccountStatusModalCallback } from '@semmie/schemas/bi/account';
import { ModalSize } from '@semmie/schemas/components/modal';
import { TaskService } from '@semmie/services/task/task.service';
import { HapticFeedbackService } from '@semmie/services/haptic-feedback/haptic-feedback.service';
import { ImpactStyle } from '@capacitor/haptics';
import { PersonService } from '@semmie/services/person/person.service';
import { SemmiePhonePipe } from '@semmie/pipes/phone/phone.pipe';
import { ToastService } from '@semmie/services/toast/toast.service';
import { GenericErrorToast } from '@semmie/schemas/components/toast';
import { Person } from '@semmie/models';
import { TaskInitiator } from '@semmie/schemas/bi/task/task-initiator.enum';
import { OrganisationService } from '@semmie/services';
import { Organisation } from '@semmie/models/bi/organisation/organisation.model';
import { QuestionnaireSubject } from '@onyxx/model/questionnaire';
import { UserStoreFacade } from '@semmie/store/user';
import { User } from '@onyxx/model/user';
import { InvestorProfileRouteNames } from '@semmie/views/settings/investor-profile/investor-profile.common';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CriticalTaskRouteNames } from 'apps/client-portal/src/app/app.common';
import { PhoneNumberStoreFacade } from '@onyxx/store/phone-number';
import { filterNil } from '@onyxx/utility/observables';
import { Utils } from '@onyxx/utility/general';
import { AppRouteNames, MainRouteNames } from '@onyxx/model/main';
import { Router } from '@angular/router';
import { SettingsRouteNames } from '@semmie/views/settings/settings.common';
import { AccountStatus } from '@onyxx/model/account';

export type TaskVM = {
  task: Task;
  description: string;
  image?: OnyxxImage;
  /** Where the user can proceed to handle the task */
  redirectUrl: (string | object)[];
  /** Urls that are available to reach for the task */
  allowedUrls: (string | object)[][];
  /** The name of the person or organization */
  subject: string;
  button: string;
};

@Injectable({ providedIn: 'root' })
export class OpenTasksService {
  taskLoaded$ = new BehaviorSubject(false);
  readonly previousTask$ = new BehaviorSubject<Task | null>(null);

  skippedTaskIds$$ = new BehaviorSubject<string[]>([]);

  private tasksThatNeedAttention$ = combineLatest([this.tasksStore.tasks$, this.skippedTaskIds$$]).pipe(
    skipUntil(this.tasksStore.loading$.pipe(filter((loading) => loading === false))),
    map(([tasks, skippedTaskIds]) => tasks.filter((task) => !skippedTaskIds.includes(task.id))),
    map((tasks) => tasks.filter((task) => [TaskPriority.CRITICAL, TaskPriority.HIGH].includes(task.priority))),
    map((tasks) => tasks.filter((task) => task.kind !== TaskKind.UPLOAD)),
    map((priorityTasks) =>
      priorityTasks.sort((a, b) => {
        if (a.priority === b.priority) return 0;
        if (a.priority === TaskPriority.CRITICAL) return -1;
        if (a.priority === TaskPriority.HIGH) return 1;
        return 0;
      }),
    ),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  taskToBeHandled$ = this.tasksThatNeedAttention$.pipe(
    map((tasks) => tasks[0]),
    distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
    switchMap((task) => {
      return task
        ? combineLatest([
            this.taskService.get(task.id).pipe(take(1)),
            task.person ? this.personService.getPersonById(task.person.id).pipe(filter(Utils.isNotNil), take(1)) : of(undefined),
            this.userFacade.latestUser$.pipe(take(1)),
            this.organisationService.list().pipe(take(1)),
            task?.questionnaires?.[0]?.organisation_id
              ? this.organisationService.get(task.questionnaires[0].organisation_id, true).pipe(take(1))
              : of(undefined),
          ])
        : of([undefined]);
    }),
    map(([task, person, user, organisations, organisation]) => this.openTaskMapper(task, person, user, organisations, organisation)),
    tap(() => {
      this.taskLoaded$.next(true);
    }),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  private hasOrganisations = false;

  constructor(
    private userFacade: UserStoreFacade,
    private modalService: ModalService,
    private navigationService: NavigationService,
    private tasksStore: TasksStore,
    private taskService: TaskService,
    private translate: TranslateService,
    private hapticFeedbackService: HapticFeedbackService,
    private personService: PersonService,
    private semmiePhonePipe: SemmiePhonePipe,
    private toastService: ToastService,
    private organisationService: OrganisationService,
    private router: Router,
    private phoneNumberStoreFacade: PhoneNumberStoreFacade,
  ) {
    this.initialize();
  }

  initialize(): void {
    this.userFacade.user$
      .pipe(
        filterNil(),
        switchMap(() => this.taskService.list()),
        takeUntilDestroyed(),
      )
      .subscribe();
  }

  reset(): void {
    this.skippedTaskIds$$.next([]);
  }

  /**
   * Opens pending task modal if needed
   */
  checkTasks(): void {
    this.userFacade.taskCountNormal$
      .pipe(debounceTime(500), withLatestFrom(this.taskToBeHandled$), take(1))
      .subscribe(([taskCountNormal, taskToBeHandled]) => {
        if (
          taskCountNormal &&
          Utils.isNil(taskToBeHandled) &&
          !this.router.isActive(`${MainRouteNames.Settings}/${SettingsRouteNames.Tasks}`, {
            paths: 'exact',
            queryParams: 'ignored',
            fragment: 'ignored',
            matrixParams: 'ignored',
          })
        ) {
          this.openPendingTasksModal();
        }
      });
  }

  skipTaskForNow(id: string) {
    this.skippedTaskIds$$.next([...this.skippedTaskIds$$.value, id]);
  }

  // TODO: Refactor Questionnaire task logic (SD-1048)
  openTaskMapper(
    task?: Task | null,
    person?: Person,
    user?: User,
    organisations?: Organisation[],
    organisation?: Organisation,
  ): TaskVM | null {
    if (Utils.isNil(task)) return null;
    this.hasOrganisations = Utils.isNotNil(organisations) && Utils.arrayIsNotEmpty(organisations);
    return {
      task,
      description: this.getDescriptionForTask(task, person, user, organisation),
      image: this.getIllustrationForTask(task),
      redirectUrl: this.getRedirectionUrlForTask(task) ?? [],
      allowedUrls: [],
      subject: this.getSubjectForTask(task, user, organisation),
      button: this.getButtonTextForTask(task),
    };
  }

  startTask(taskVM: TaskVM) {
    if (taskVM.task.name === UserTaskName.PHONE_VERIFICATION) {
      this.startPhoneVerifyTask(taskVM);
      return;
    }
    return this.navigationService.navigate(taskVM.redirectUrl);
  }

  checkCriticalTasks() {
    this.taskLoaded$.next(false);
    this.taskService.list().pipe(take(1)).subscribe();

    this.taskToBeHandled$
      .pipe(
        tap((taskVm) => {
          if (taskVm) this.previousTask$.next(taskVm.task);
        }),
        filter(Utils.isNil),
        switchMap(() => this.previousTask$),
        tap((previousTask) => {
          if (!previousTask?.isMigrationTask()) this.navigationService.toDashboard(false, true);
        }),
        take(1),
      )
      .subscribe();
  }

  private async openPendingTasksModal(): Promise<void> {
    await this.modalService.open(
      AccountStatusModalComponent,
      {
        componentProps: {
          status: AccountStatus.TASK,
        },
      },
      { size: ModalSize.Full },
    );
    this.hapticFeedbackService.interact(ImpactStyle.Heavy);

    this.modalService.onWillClose$.pipe(take(1)).subscribe((closeCallback: AccountStatusModalCallback) => {
      if (closeCallback === AccountStatusModalCallback.TASK) {
        this.navigateToTasks();
      }
    });
  }

  // TODO: Extract phone verify functions to it's own service.
  private async startPhoneVerifyTask(taskVM: TaskVM) {
    const personId = taskVM.task?.person?.id;

    if (Utils.isNil(personId)) {
      this.toastService.show(GenericErrorToast());
      return;
    }

    const person = await firstValueFrom(this.personService.getPersonById(personId));
    if (Utils.isNil(person) || Utils.isNil(person.phone) || Utils.isNil(person.country_phone)) {
      this.toastService.show(GenericErrorToast());
      return;
    }

    this.phoneNumberStoreFacade.dispatchSetupVerification({
      personId,
      phone: person.phone,
      country_phone: person.country_phone,
    });

    this.phoneNumberStoreFacade.dispatchResendCode();

    // navigate to edit page
    this.navigationService.navigate([AppRouteNames.CriticalTasks, CriticalTaskRouteNames.PhoneVerification], { animated: true });
  }

  private getSubjectForTask(task: Task, user?: User, organisation?: Organisation) {
    if (task.name === UserTaskName.PHONE_VERIFICATION) {
      return this.translate.instant('tasks.phone_verification.title');
    } else if (task.kind === TaskKind.OPENING_PROFILE) {
      if (task.questionnaires?.[0]) {
        const questionnaireSubject = task.questionnaires[0].subject;
        const tranlateKey = organisation
          ? `task-questionnaire.intro.organisation.${questionnaireSubject}.title`
          : `task-questionnaire.intro.${questionnaireSubject}.title`;
        return this.translate.instant(tranlateKey, { title: organisation?.title });
      } else {
        return this.translate.instant('task-questionnaire.intro.knowledge_experience.title');
      }
    } else if (task.kind === TaskKind.INPUT_VALUES && task.person && user) {
      if (task.person.id === user?.person?.id) {
        return this.translate.instant('tasks.your_details');
      } else {
        return this.translate.instant('tasks.others_details', { name_casual: task.person.name_casual });
      }
    }

    return task.organisation?.name_casual ?? task.person?.name_casual ?? task.message?.title ?? task.title;
  }

  private getDescriptionForTask(task: Task, person?: Person, user?: User, organisation?: Organisation): string {
    if (task.name === UserTaskName.PHONE_VERIFICATION) {
      if (person && Utils.isNotNil(person.phone) && Utils.isNotNil(person.country_phone)) {
        const formattedPhoneNumber = this.semmiePhonePipe.transform(person.phone, person.country_phone.iso3);

        const descriptionKey =
          task.initiator === TaskInitiator.MIGRATION && Utils.isNil(user?.origin?.title) ? TaskInitiator.SYSTEM : task.initiator;
        return this.translate.instant(`tasks.phone_verification.description.${descriptionKey}`, {
          origin: user?.origin,
          phone: formattedPhoneNumber,
        });
      } else {
        return this.translate.instant('tasks.phone_verification.description.no_number');
      }
    } else if (task.kind === TaskKind.OPENING_PROFILE) {
      if (task.questionnaires?.[0]) {
        const questionnaireSubject = task.questionnaires[0].subject;
        const tranlateKey = organisation
          ? `task-questionnaire.intro.organisation.${questionnaireSubject}.description`
          : `task-questionnaire.intro.${questionnaireSubject}.description`;

        const tranlateKeyWarning = organisation
          ? `task-questionnaire.intro.organisation.${questionnaireSubject}.warning`
          : `task-questionnaire.intro.${questionnaireSubject}.warning`;
        const warning =
          this.hasOrganisations && questionnaireSubject === QuestionnaireSubject.RISK_APPETITE
            ? '<br/><br/>' + this.translate.instant(tranlateKeyWarning, { title: organisation?.title })
            : '';

        return this.translate.instant(tranlateKey, { title: organisation?.title }) + warning;
      } else {
        return this.translate.instant('questionnaire.intro.knowledge_experience.description');
      }
    } else {
      return task?.message?.description ?? '';
    }
  }

  private getIllustrationForTask(task: Task): OnyxxImage | undefined {
    if (task.name === UserTaskName.PHONE_VERIFICATION) {
      return undefined;
    }

    if (Utils.isNotNil(task.organisation)) {
      return Illustration.ORGANISATION_BUILDING;
    }

    if (task.kind === TaskKind.SUSTAINABILITY_PREFERENCE) {
      return Illustration.BUILDING_ECO;
    }

    if (task.kind === TaskKind.OPENING_PROFILE) {
      if (task.questionnaires?.[0]) {
        return this.translate.instant(`task-questionnaire.intro.${task.questionnaires[0].subject}.image`);
      } else {
        return Illustration.CLIPBOARD;
      }
    }

    if (task.kind === TaskKind.INPUT_VALUES) {
      return Illustration.CLIPBOARD;
    }

    return Illustration.PAPER_PLANE;
  }

  // Url to execute the task
  private getRedirectionUrlForTask(task: Task) {
    if (task.kind === TaskKind.SUSTAINABILITY_PREFERENCE) {
      return [
        '/',
        MainRouteNames.Settings,
        InvestorProfileRouteNames.InvestorProfile,
        InvestorProfileRouteNames.SustainabilityPreference,
        { skipIntros: true, showSuccessModal: false },
      ];
    }

    if (task.kind === TaskKind.PAYMENT) {
      const paymentType = task.wid_payment ? 'task_first_wid' : 'first';
      return ['/', MainRouteNames.Accounts, task.message?.account?.id, 'payment', { step: paymentType }];
    }

    if (task.kind === TaskKind.INPUT_VALUES) {
      if (task.name === 'cdd_organisation_re_examination') {
        return ['/', MainRouteNames.Settings, 'cdd', 'organisation', task.organisation?.id];
      }
      if (Utils.isNotNil(task.person)) {
        return ['/', MainRouteNames.Settings, 'cdd', task.person.id, { task: task.id }];
      }
    }

    if (task.kind === TaskKind.OPENING_PROFILE) {
      if (task.questionnaires?.[0]) {
        return [
          '/',
          MainRouteNames.Settings,
          InvestorProfileRouteNames.InvestorProfile,
          task.questionnaires[0].subject.replace('_', '-'),
          ...(task.questionnaires[0].organisation_id ? [task.questionnaires[0].organisation_id] : []),
          { skipIntros: true, showSuccessModal: false },
        ];
      } else {
        /**
         * Taken from v1 business logic
         */
        return [
          '/',
          MainRouteNames.Settings,
          InvestorProfileRouteNames.InvestorProfile,
          InvestorProfileRouteNames.KnowledgeExperience,
          { skipIntros: true, showSuccessModal: false },
        ];
      }
    }
  }

  private getButtonTextForTask(task: Task): string {
    if (task.name === UserTaskName.PHONE_VERIFICATION) {
      const person = this.personService.person.value;
      if (Utils.isNotNil(person?.phone) && Utils.isNotNil(person?.country_phone)) {
        return this.translate.instant('core.common.labels.send');
      }
    }

    return this.translate.instant('core.common.labels.next');
  }

  private navigateToTasks() {
    this.navigationService.navigate(['/', MainRouteNames.Settings, 'tasks'], { animated: true });
  }
}

import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  HostBinding,
  Input,
  TemplateRef,
} from '@angular/core';
import { PageHeaderComponent } from '@semmie/components/containers/page-header/page-header.component';

import { BaseComponent } from '@semmie/components/_abstract';
import { Utils } from '@semmie/shared/utils';

@Component({
  selector: 'semmie-content-container',
  template: `
    <div class="wrapper" [ngClass]="getWrapperStyling()">
      <ng-content select="semmie-modal-header"></ng-content>
      <ng-container *ngTemplateOutlet="headerTpl"></ng-container>
      <div class="content-container" [ngClass]="[getStyling(), hasContent ? 'pb-8' : 'pb-0']">
        <ng-content select="semmie-content-container-top"></ng-content>

        <div #content class="content">
          <ng-content></ng-content>
        </div>
      </div>
    </div>
    <ng-content select="semmie-content-container-bottom"></ng-content>

    <ng-template #headerTpl>
      <ng-container *ngIf="pageHeaderRef" [ngTemplateOutlet]="pageHeaderRef"> </ng-container>
      <ng-container *ngIf="pageHeaderIntroRef" [ngTemplateOutlet]="pageHeaderIntroRef"> </ng-container>
      <ng-content *ngIf="!pageHeaderRef || !pageHeaderIntroRef" select="semmie-page-header"></ng-content>
      <semmie-page-header *ngIf="title" [title]="title" titleSize="h1" [leftAction]="false"></semmie-page-header>
    </ng-template>
  `,
  styleUrls: ['./content-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentContainerComponent extends BaseComponent implements AfterContentInit {
  @ContentChild('pageHeader', { static: false }) pageHeaderRef: TemplateRef<any>;
  @ContentChild('pageHeaderIntro', { static: false }) pageHeaderIntroRef: TemplateRef<any>;
  @ContentChild(PageHeaderComponent) pageHeaderComponents: TemplateRef<PageHeaderComponent>;
  @ContentChild('content', { static: false }) content: TemplateRef<any>;

  @HostBinding('attr.title') _title = '';
  @Input() title: string;
  @Input() size: 'sm' | 'md' | 'lg' | '2xl' | '6xl' | 'full' = '6xl';
  @Input() padding: 'px-0' | 'px-4' | 'px-6' | 'px-10' = 'px-4';
  @Input() wrapperPadding: 'pt-0' | 'pt-14' = 'pt-14';
  @Input() styling: 'rounded' | 'normal' = 'normal';
  @Input() background: string;
  @Input() class: string;

  hasPageheader = false;
  hasContent = false;

  constructor(private cdr: ChangeDetectorRef) {
    super();
  }

  getStyling() {
    const classes = new Array<string>();
    if (this.size) classes.push(`size-${this.size}`);
    if (this.padding) classes.push(this.padding);
    return classes.join(' ');
  }

  getWrapperStyling() {
    const classes = new Array<string>();
    if (this.class) this.class.split(' ').forEach((c) => classes.push(c));
    if (this.hasPageheader) {
      classes.push('pt-0');
    } else {
      classes.push(this.wrapperPadding);
    }
    return classes.join(' ');
  }

  ngAfterContentInit() {
    this.hasPageheader =
      Utils.isNonNullOrUndefined(this.pageHeaderComponents) ||
      Utils.isNonNullOrUndefined(this.pageHeaderRef) ||
      Utils.isNonNullOrUndefined(this.pageHeaderIntroRef);
    this.hasContent = Utils.isNonNullOrUndefined(this.content);
    this.cdr.detectChanges();
  }
}

import { formatPercent } from '@angular/common';
import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'semmiePercentage',
})
export class SemmiePercentagePipe implements PipeTransform {
  constructor(@Inject(LOCALE_ID) private locale: string) {}

  transform(value: number | string | null = 0, digitsInfo?: string, showPositiveNotation = false) {
    value = Number(String(value).replace('%', '').replace(',', '.')) / 100 || 0;
    let prefix = '';

    const isNegative = value < 0;
    const positiveSymbol = showPositiveNotation ? '\ufe62' : '';
    value = Math.abs(value);
    if (value !== 0) {
      prefix = isNegative ? '\ufe63' : positiveSymbol;
    }
    return `${prefix}${formatPercent(value, this.locale, digitsInfo || '1.0-2')}`;
  }
}
